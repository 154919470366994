import './css/style.css'
import './css/locomotive.css'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import noiseVertexShader from './shaders/noise/vertex.glsl'
import noiseFragmentShader from './shaders/noise/fragment.glsl'
import workVertexShader from './shaders/work/vertex.glsl'
import workFragmentShader from './shaders/work/fragment.glsl'
import LocomotiveScroll from 'locomotive-scroll'
import Splitting from "splitting"
import { Howl } from 'howler'
import createjs from 'preload-js'

gsap.registerPlugin(ScrollTrigger)
Splitting()

//////---------------------------------------TEXTURES LOADER---------------------------------------//////
const textureLoader = new THREE.TextureLoader()
const alphaRingTexture = textureLoader.load('/textures/alpha/alphaRing.jpg')
const alphaTexture = textureLoader.load('/textures/alpha/alphaTexture.jpg')
const displacement = textureLoader.load('/textures/displacement/displacement.jpg')
const idTexture = textureLoader.load('/textures/img/id.jpg')
const astralysTexture = textureLoader.load('/textures/img/astralys.jpg')
const aurianeTexture = textureLoader.load('/textures/img/auriane.jpg')
const folio2020Texture = textureLoader.load('/textures/img/folio2020.jpg')
const folio2019Texture = textureLoader.load('/textures/img/folio2019.jpg')
const gamovoreTexture = textureLoader.load('/textures/img/gamovore.jpg')
const internshipTexture = textureLoader.load('/textures/img/internship.jpg')
const mmorpionTexture = textureLoader.load('/textures/img/mmorpion.jpg')
const laDetteTexture = textureLoader.load('/textures/img/laDette.jpg')
const motionIntroTexture = textureLoader.load('/textures/img/motionIntro.jpg')
const outroIdTexture = textureLoader.load('/textures/img/outroId.jpg')
const yasuoBackgroundTexture = textureLoader.load('/textures/img/3dBackground.jpg')
const animayoTexture = textureLoader.load('/textures/img/animayo.jpg')
const cmsFolioTexture = textureLoader.load('/textures/img/cmsFolio.jpg')
const letterMaskTexture = textureLoader.load('/textures/img/letterMask.jpg')
const newspaperTexture = textureLoader.load('/textures/img/newspaper.jpg')
const terreDeBoisTexture = textureLoader.load('/textures/img/terreDeBois.jpg')
const mushroomsTexture = textureLoader.load('/textures/img/mushrooms.jpg')
const dressOnTexture = textureLoader.load('/textures/img/dressOn.jpg')
const jamOnTexture = textureLoader.load('/textures/img/jamOn.jpg')

const cubeTextureLoader = new THREE.CubeTextureLoader()

const environmentMapTexture = cubeTextureLoader.load([
  '/textures/environmentMaps/px.jpg',
  '/textures/environmentMaps/nx.jpg',
  '/textures/environmentMaps/py.jpg',
  '/textures/environmentMaps/ny.jpg',
  '/textures/environmentMaps/pz.jpg',
  '/textures/environmentMaps/nz.jpg'
])

//////---------------------------------------DOM---------------------------------------//////
const body = document.querySelector('body'),
  html = document.querySelector('html'),
  app = document.querySelector('#app'),
  mainCanvas = document.querySelector('.canvas--main'),
  noiseCanvas = document.querySelector('.canvas--noise'),
  loaderContainer = document.querySelector('.loaderContainer'),
  numberProgress = document.querySelector('.progress--number'),
  buttonProgress = document.querySelectorAll('.progress--button'),
  menu = document.querySelector('.menuReveal'),
  hamburgerWrapper = document.querySelector('.hamburgerWrapper'),
  lineContainer__1 = document.querySelector('.lineContainer--1'),
  lineContainer__3 = document.querySelector('.lineContainer--3'),
  lineContainer__4 = document.querySelector('.lineContainer--4'),
  hoverLine__1 = document.querySelector('.lineContainer--2 .line--1'),
  hoverLine__2 = document.querySelector('.lineContainer--2 .line--2'),
  crossLine__1 = document.querySelector('.lineContainer--3 .line--1'),
  crossLine__2 = document.querySelector('.lineContainer--3 .line--2'),
  allCrossLine = document.querySelectorAll('.lineContainer--3 .line'),
  hoverCrossLine__1 = document.querySelector('.lineContainer--4 .line--1'),
  hoverCrossLine__2 = document.querySelector('.lineContainer--4 .line--2'),
  allHoverCrossLine = document.querySelectorAll('.lineContainer--4 .line'),
  homeBtn = document.querySelectorAll('.homeLink'),
  homeBtnAbout = document.querySelector('.homeLink--about'),
  homeBtnWork = document.querySelector('.homeLink--work'),
  homeBtnShowreel = document.querySelector('.homeLink--showreel'),
  media = document.querySelectorAll('.linkWrapper .media a'),
  menuBtn = document.querySelectorAll('.menuReveal .linkWrapper .link'),
  homeMenuBtn = document.querySelector('.menuReveal .linkWrapper .link--home'),
  aboutMenuBtn = document.querySelector('.menuReveal .linkWrapper .link--about'),
  workMenuBtn = document.querySelector('.menuReveal .linkWrapper .link--work'),
  showreelMenuBtn = document.querySelector('.menuReveal .linkWrapper .link--showreel'),
  homeContainer = document.querySelector('.container--home'),
  aboutContainer = document.querySelector('.container--about'),
  workContainer = document.querySelector('.container--work'),
  workRevealMask = document.querySelector('.workRevealMask'),
  workRevealContainer = document.querySelector('.workRevealContainer'),
  video = document.querySelector('video'),
  rotateMobileIndication = document.querySelector('.rotateMobileIndication'),
  project = document.querySelectorAll('.projectWrapper'),
  project1 = document.querySelector('.projectWrapper--1'),
  project2 = document.querySelector('.projectWrapper--2'),
  project3 = document.querySelector('.projectWrapper--3'),
  project4 = document.querySelector('.projectWrapper--4'),
  project5 = document.querySelector('.projectWrapper--5'),
  project6 = document.querySelector('.projectWrapper--6'),
  project7 = document.querySelector('.projectWrapper--7'),
  project8 = document.querySelector('.projectWrapper--8'),
  project9 = document.querySelector('.projectWrapper--9'),
  project10 = document.querySelector('.projectWrapper--10'),
  project11 = document.querySelector('.projectWrapper--11'),
  project12 = document.querySelector('.projectWrapper--12'),
  project13 = document.querySelector('.projectWrapper--13'),
  project14 = document.querySelector('.projectWrapper--14'),
  project15 = document.querySelector('.projectWrapper--15'),
  project16 = document.querySelector('.projectWrapper--16'),
  project17 = document.querySelector('.projectWrapper--17'),
  project18 = document.querySelector('.projectWrapper--18'),
  project19 = document.querySelector('.projectWrapper--19'),
  project20 = document.querySelector('.projectWrapper--20'),
  allImg = document.querySelectorAll('img'),
  faceImg = document.querySelector('.aboutWrapper--2 img'),
  paraWorkReveal = document.querySelector('.workRevealWrapper p'),
  imgWorkReveal = document.querySelector('.workRevealContainer .workRevealWrapper .section--2 img'),
  linkProjectWorkReveal = document.querySelector('.workRevealContainer .linkProject'),
  switchWorkTitle = document.querySelectorAll('.workRevealContainer .title'),
  allCursor = document.querySelectorAll(".cursor"),
  customCursorDot = document.querySelector(".cursor--dot"),
  customCursor = document.querySelector(".cursor--main"),
  customCursorIndication = document.querySelector(".cursor--indication"),
  customCursorFollower = document.querySelector(".cursor--follower"),
  cursorLoader = document.querySelector('.cursorLoader'),
  heySection = document.querySelector('.heySection'),
  aboutTitle = document.querySelector('.container--about .title--about'),
  aboutPresentation = document.querySelector('.aboutWrapper--2'),
  courseTitle = document.querySelector('.container--about .title--course'),
  courseP1 = document.querySelector('.courseSection--1'),
  courseP2 = document.querySelector('.courseSection--2'),
  courseP3 = document.querySelector('.courseSection--3'),
  skillsTitle = document.querySelector('.container--about .title--skills'),
  skillsWebDev = document.querySelector('.skillsSection--1'),
  skillsDesign = document.querySelector('.skillsSection--2'),
  skillsMotion = document.querySelector('.skillsSection--3')

customCursorDot.style.transform = "rotate(45deg)"

//////---------------------------------------VARS---------------------------------------//////
let switchSection = "home",
  currentTexture = idTexture,
  switchMenu = false,
  revealWork = false,
  cancelOpacityScrollAnimation = true,
  switchWork,
  switchHomeToSection = true,
  boolRingRotation = false,
  mousePressed = false,
  pressEvent = false,
  mousePressedActive = true,
  switchToShowreel = false,
  switchCursorIndication = false,
  switchNoise,
  shakeX = 0,
  thresholdPresentation,
  staggerHover = .065,
  customCursorDotPos = { x: window.innerWidth / 2, y: window.innerHeight / 2 },
  customCursorDotMouse = { x: customCursorDotPos.x, y: customCursorDotPos.y },
  customCursorSpeed = 1,
  customCursorDotXSet = gsap.quickSetter(customCursorDot, "x", "px"),
  customCursorDotYSet = gsap.quickSetter(customCursorDot, "y", "px"),
  customCursorPos = { x: window.innerWidth / 2, y: window.innerHeight / 2 },
  customCursorMouse = { x: customCursorPos.x, y: customCursorPos.y },
  customCursorXSet = gsap.quickSetter(customCursor, "x", "px"),
  customCursorYSet = gsap.quickSetter(customCursor, "y", "px"),
  customCursorIndicationPos = { x: window.innerWidth / 2, y: window.innerHeight / 2 },
  customCursorIndicationMouse = { x: customCursorIndicationPos.x, y: customCursorIndicationPos.y },
  customCursorIndicationXSet = gsap.quickSetter(customCursorIndication, "x", "px"),
  customCursorIndicationYSet = gsap.quickSetter(customCursorIndication, "y", "px"),
  customCursorFollowerPos = { x: window.innerWidth / 2, y: window.innerHeight / 2 },
  customCursorFollowerMouse = { x: customCursorFollowerPos.x, y: customCursorFollowerPos.y },
  customCursorFollowerXSet = gsap.quickSetter(customCursorFollower, "x", "px"),
  customCursorFollowerYSet = gsap.quickSetter(customCursorFollower, "y", "px"),
  mouseX = 0,
  mouseY = 0,
  targetX = 0,
  targetY = 0,
  activityTimer = setTimeout(inActive, 500),
  cursorValue = 0,
  switchCursorIncrementation = false,
  targetXInvert = 0,
  windowHalfX = window.innerWidth / 2,
  windowHalfY = window.innerHeight / 2,
  ringGroupRotation = .003,
  smooth = 2.5,
  lightMouse = new THREE.Vector2(0, 0),
  musicMouse = new THREE.Vector2(0, 0),
  musicPlayed = true,
  posXMusic,
  posYMusic,
  bgMusic = new Howl({
    src: ['/sounds/bg.mp3'],
    loop: true,
    volume: 0.5
  }),
  showreelSound = new Howl({
    src: ['/sounds/showreelSound.mp3'],
    loop: true,
    volume: 0
  })

video.src = '/textures/videos/showreel.mp4'
video.volume = 0.15
faceImg.src = '/textures/img/thoma.jpg'

//mouse functions
function cursorHoverRing(e) {
  e.addEventListener('mouseenter', () => {
    gsap.to(customCursorDot, .5, { padding: '25px', borderRadius: "100%", background: 'transparent', border: '1px solid #b1b1b1', ease: 'power3' })
    gsap.to(customCursor, .5, { opacity: 0, padding: '30px', borderRadius: '100%', ease: 'power3' })
    gsap.to(customCursorIndication, .5, { opacity: 0, ease: 'power3' })
    gsap.to(customCursorFollower, .5, { opacity: 0, padding: '40px', borderRadius: '100%', ease: 'power3' })
  })
  e.addEventListener('mouseleave', () => {
    gsap.to(customCursorDot, .5, { padding: '0', borderRadius: "0%", background: '#b1b1b1', border: '0pw solid transparent', ease: 'power3.in' })
    gsap.to(customCursor, .5, { opacity: 1, borderRadius: '0%', padding: '0px', ease: 'power3.in' })
    gsap.to(customCursorIndication, .5, { opacity: 1, ease: 'power3.in' })
    gsap.to(customCursorFollower, .5, { opacity: .5, borderRadius: '0%', padding: '0px', ease: 'power3.in' })
  })
}

function cursorHoverText(e) {
  e.forEach(m => {
    m.addEventListener('mousemove', () => {
      gsap.to(customCursorDot, .35, { padding: '20px', borderRadius: '100%', ease: 'power3' })
      gsap.to(customCursor, .35, { opacity: 0, borderRadius: '100%', ease: 'power3' })
      gsap.to(customCursorIndication, .35, { opacity: 0, ease: 'power3' })
      gsap.to('.octagon', .35, { background: 'rgba(177, 177, 177, 1)', ease: 'power3' })
      gsap.to(customCursorFollower, .35, { opacity: 0, borderRadius: '100%', ease: 'power3' })
    })
    m.addEventListener('mouseleave', () => {
      gsap.to(customCursorDot, .35, { padding: '0', borderRadius: '0%', ease: 'power3.in' })
      gsap.to(customCursor, .35, { opacity: 1, borderRadius: '0%', ease: 'power3.in' })
      gsap.to(customCursorIndication, .35, { opacity: 1, ease: 'power3.in' })
      gsap.to('.octagon', .35, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
      gsap.to(customCursorFollower, .35, { opacity: .5, borderRadius: '0%', ease: 'power3.in' })
    })
    m.addEventListener('click', () => {
      gsap.to(customCursorDot, .35, { padding: '0', borderRadius: '0%', ease: 'power3.in' })
      gsap.to(customCursor, .35, { opacity: 1, borderRadius: '0%', ease: 'power3.in' })
      gsap.to('.octagon', .35, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
      gsap.to(customCursorFollower, .35, { opacity: .5, borderRadius: '0%', ease: 'power3.in' })
    })
  })
}

function resetActive() {
  gsap.to('.cursor--indication span', .5, { opacity: 0, ease: 'power3' })
  clearTimeout(activityTimer)
  activityTimer = setTimeout(inActive, 500)
}

function inActive() {
  if (pressEvent == true && switchCursorIndication == false && switchSection != 'showreel' && switchSection != 'workReveal') {
    gsap.to('.cursor--indication span', .5, { opacity: 1, ease: 'power3.in' })
  }
}

//////---------------------------------------SOUND BTN---------------------------------------//////
const lerp = (v0, v1, t) => (v0 * (1 - t) + v1 * t)
const deg = (a) => a * Math.PI / 180

class AudioSwitcher {
  constructor(opt) {
    Object.assign(this, opt)

    this.active = true
    this.hover = false

    if (window.matchMedia("(max-width: 767px)").matches) {
      this.settings = {
        width: 50,
        height: 6.9,
        amplitude: -0.18,
        hoverHeight: 1,
        hoverAmplitude: -0.12,
        speed: 5
      }
    }
    else {
      this.settings = {
        width: 60,
        height: 6.9,
        amplitude: -0.18,
        hoverHeight: 1,
        hoverAmplitude: -0.12,
        speed: 5
      }
    }

    this.init()
  }

  init() {
    this.hover = true

    this.button.addEventListener('click', () => {
      this.active = !this.active
      this.button.classList.toggle('active')
    })

    cursorHoverRing(this.button)

    this.ctx = this.soundCanvas.getContext('2d')
    this.width = this.soundCanvas.clientWidth
    this.height = this.soundCanvas.clientHeight
    this.amp = 0
    this.h = 0
    this.soundCanvas.width = this.width
    this.soundCanvas.height = this.height
    this.soundCanvas.style.width = `${this.width}px`
    this.soundCanvas.style.height = `${this.height}px`
    this.ctx.scale(1, 1)
  }

  clear() {
    this.ctx.clearRect(0, 0, this.soundCanvas.width, this.soundCanvas.height)
  }

  draw(time) {
    this.ctx.fillStyle = this.color

    for (let i = 0; i < this.settings.width; i++) {
      this.ctx.beginPath()
      const x = (this.width / 2) - (this.settings.width / 2 * 0.5) + i * .6
      const t = (time * this.settings.speed) + (i * this.amp)
      const y = (this.height / 2) + (-Math.cos(t) * this.h)
      this.ctx.ellipse(x, y, .5, .5, deg(360), 0, deg(360))
      this.ctx.closePath()
      this.ctx.fill()
    }
  }

  animate(time) {
    let height = this.hover ? this.settings.hoverHeight : 0
    height = this.active ? this.settings.height : height
    this.h = lerp(this.h, height, 0.04)

    let amplitude = this.hover ? this.settings.hoverAmplitude : 0
    amplitude = this.active ? this.settings.amplitude : amplitude
    this.amp = lerp(this.amp, amplitude, 0.04)

    this.clear()
    this.draw(time)
  }
}

const audioSwitcher = new AudioSwitcher({
  button: document.querySelector('.musicWrapper'),
  soundCanvas: document.querySelector('.canvas--music'),
  color: "#b1b1b1",
})

audioSwitcher.button.addEventListener('click', () => {
  if (musicPlayed) {
    musicPlayed = false
    bgMusic.fade(.5, 0, 1000)
  } else {
    musicPlayed = true
    bgMusic.fade(0, .5, 1000)
  }
})

//////---------------------------------------LOADER---------------------------------------//////
const queue = new createjs.LoadQueue(false)

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
  document.addEventListener('click', () => {
    if (html.requestFullscreen) {
      html.requestFullscreen()
    } else if (html.mozRequestFullScreen) {
      html.mozRequestFullScreen()
    } else if (html.webkitRequestFullscreen) {
      html.webkitRequestFullscreen()
    } else if (html.msRequestFullscreen) {
      html.msRequestFullscreen()
    }
  })
}

queue.on("progress", event => {
  let increment = Math.floor(event.progress * 100)
  numberProgress.innerHTML = increment

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
  } else {
    setTimeout(function () {
      allCursor.forEach(cursor => {
        cursor.style.display = 'block'
      })
      gsap.to(customCursor, .25, { opacity: 1, ease: 'power3' })
      gsap.to(customCursorDot, .25, { opacity: 1, ease: 'power3' })
      gsap.to(customCursorFollower, .25, { opacity: .5, ease: 'power3' })
    }, 25)
  }
})

queue.on("complete", () => {
  cursorHoverText(buttonProgress)

  gsap.to(noiseCanvas, 3, { opacity: 1, ease: 'power3' })
  gsap.to(numberProgress, .75, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', transform: 'translateY(-15px)', ease: 'power3' })
  gsap.to(buttonProgress, .75, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'translateY(0)', ease: 'power3' })

  buttonProgress.forEach(b => {
    b.addEventListener('click', () => {
      bgMusic.play()
      showreelSound.play()

      gsap.to(buttonProgress, .75, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', transform: 'translateY(-15px)', ease: 'power3' })

      setTimeout(function () {
        loaderContainer.style.display = 'none'

        setTimeout(function () {
          app.style.pointerEvents = 'all'
          hamburgerWrapper.style.pointerEvents = 'all'
          audioSwitcher.button.style.pointerEvents = 'all'
          homeBtn.forEach(e => {
            e.style.pointerEvents = 'all'
          })
        }, 600)
        gsap.to(camera.position, 1.5, { z: 1.5, ease: 'power3' })
        gsap.to('.container--home', 1.5, { transform: 'scale(1)', ease: 'power3' })
        gsap.to('.homeLink', 1, { opacity: .6, ease: 'power3', delay: .55 })
        gsap.to(app, 1.5, { opacity: 1, ease: 'power3' })
        gsap.to('.musicWrapper', 1.5, { opacity: 1, ease: 'power3' })
        gsap.to('.hamburgerWrapper', 1.5, { opacity: 1, ease: 'power3' })
        gsap.to(mainCanvas, 1.5, { opacity: 1, ease: 'power3' })
        setTimeout(function () {
          pressEvent = true

          if (pressEvent) {
            gsap.to('.cursor--indication span', .5, { opacity: 1, ease: 'power3.in' })
          }
        }, 1500)
      }, 1000)
    })
  })
})

queue.on("fileload", handleFileComplete)

queue.loadFile('/textures/alpha/alphaRing.jpg')
queue.loadFile('/textures/alpha/alphaTexture.jpg')
queue.loadFile('/textures/displacement/displacement.jpg')
queue.loadFile('/models/logo.gltf')

function handleFileComplete() { }

//////---------------------------------------THREE.JS---------------------------------------//////
const scene = new THREE.Scene()
const noiseScene = new THREE.Scene()

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

window.addEventListener('resize', () => {
  //Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight

  //Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  //Update renderers
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  noiseRenderer.setSize(sizes.width, sizes.height)
  noiseRenderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

//////CAMERA//////
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 1000)
camera.position.z = .75
scene.add(camera)

//////NOISE CAMERA//////
const noiseCamera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 1000)
noiseScene.add(noiseCamera)

//////COLORS//////
let whiteColor = new THREE.Color(0xb1b1b1)
let sphereColor = new THREE.Color(0xffffff)
let blackColor = new THREE.Color(0x151617)

//////LIGHT//////
const ambientLight = new THREE.AmbientLight(whiteColor, 1.2)
scene.add(ambientLight)

const pointLight = new THREE.PointLight(whiteColor, 1.2)
pointLight.position.y = 3
pointLight.position.z = 4
scene.add(pointLight)

//////LOGO MATERIAL//////
const logoMaterial = new THREE.MeshStandardMaterial({
  metalness: 0.5,
  roughness: .1,
  transparent: true,
  opacity: 1,
  envMap: environmentMapTexture,
  color: new THREE.Color(blackColor)
})

//////RING MATERIAL//////
const ringMaterial = new THREE.MeshStandardMaterial({
  metalness: 0.8,
  roughness: 0,
  transparent: true,
  opacity: 0.15,
  envMap: environmentMapTexture,
  alphaMap: alphaRingTexture
})

//////SPHERE WIREFRAME MATERIAL//////
const sphereWireframeMaterial = new THREE.MeshBasicMaterial({
  wireframe: true,
  transparent: true,
  opacity: 0.015
}
)

//////CYLINDER WORK MATERIAL//////
let cylinderWorkMaterial = new THREE.ShaderMaterial({
  vertexShader: workVertexShader,
  fragmentShader: workFragmentShader,
  side: THREE.BackSide,
  transparent: true,
  uniforms: {
    u_alpha: { value: 0.0 },
    u_texture1: { type: "t", value: alphaTexture },
    u_texture2: { type: "t", value: alphaTexture },
    u_displacement: { type: "t", value: displacement },
    u_dispFactor: { type: "f", value: 0.0 },
    u_effectFactor: { type: "f", value: 1.0 },
    u_mouse: { value: lightMouse },
    u_mouseIntensity: { value: 0.01 },
    u_mouseRange: { value: 20.0 },
    u_mousePos: {
      value: new THREE.Vector2(window.innerWidth, window.innerHeight)
    }
  },
  defines: {
    PR: window.devicePixelRatio.toFixed(1)
  }
})

//////GLTFLOADER//////
let loaderLogo = new GLTFLoader
loaderLogo.crossOrigin = true

let logo

loaderLogo.load('/models/logo.gltf', function (addLogo) {
  logo = addLogo.scene

  //create material foreach model mesh
  logo.traverse((e) => {
    if (e.isMesh) e.material = logoMaterial
  })

  //logo mouvements
  logo.rotation.x = Math.PI * .5
  logo.position.y = .0325
  logo.position.x = .01
  logo.scale.set(.4, .4, .4)

  //adding logo to the scene
  scene.add(logo)
})

//////RINGS//////
let ringMesh = new THREE.Mesh(
  new THREE.RingBufferGeometry(.4, .4025, 8),
  ringMaterial
)
ringMesh.scale.set(2.8, 2.8, 2.8)
ringMesh.position.z = -6

let ringMesh2 = ringMesh.clone()
ringMesh2.scale.set(4.2, 4.2, 4.2)
ringMesh2.rotation.z = .25 * Math.PI

let ringMesh3 = ringMesh.clone()
ringMesh3.scale.set(6.3, 6.3, 6.3)

let ringMesh4 = ringMesh.clone()
ringMesh4.scale.set(9.45, 9.45, 9.45)
ringMesh4.rotation.z = .25 * Math.PI

let ringMesh5 = ringMesh.clone()
ringMesh5.scale.set(14.175, 14.175, 14.175)

//////SPHERE WIREFRAME//////
let sphereWireframeMesh = new THREE.Mesh(
  new THREE.SphereBufferGeometry(35, 100, 100),
  sphereWireframeMaterial
)

sphereWireframeMesh.position.z = -27.5

//////CYLINDER WORK//////
let cylinderWorkMesh = new THREE.Mesh(
  new THREE.CylinderBufferGeometry(30, 30, 35.4, 100, 1, true, .5 * Math.PI, Math.PI),
  cylinderWorkMaterial
)
cylinderWorkMesh.position.z = -27.5

let ringGroup = new THREE.Group()
ringGroup.position.z = 0.05

ringGroup.add(ringMesh, ringMesh2, ringMesh3, ringMesh4, ringMesh5)
scene.add(sphereWireframeMesh, ringGroup)

//////MAIN RENDERER//////
const renderer = new THREE.WebGLRenderer({ canvas: mainCanvas, antialias: true, alpha: true })
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

//////NOISE RENDERER//////
const noiseRenderer = new THREE.WebGLRenderer({ canvas: noiseCanvas, antialias: true, alpha: true })
noiseRenderer.setSize(sizes.width, sizes.height)
noiseRenderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

//////NOISE COMPOSER//////
let noiseComposer = new EffectComposer(noiseRenderer)
let noiseRenderPass = new RenderPass(noiseScene, noiseCamera)
noiseComposer.addPass(noiseRenderPass)

//custom shader pass
let counter = 0.0
let noiseEffect = {
  uniforms: {
    "tDiffuse": { value: null },
    "amount": { value: counter },
    intensity: { value: 0.08 }
  },
  vertexShader: noiseVertexShader,
  fragmentShader: noiseFragmentShader
}

let noiseCustomPass = new ShaderPass(noiseEffect)
noiseCustomPass.renderToScreen = true
noiseComposer.addPass(noiseCustomPass)

//////---------------------------------------HOME---------------------------------------//////
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
} else {
  cursorHoverRing(hamburgerWrapper)
  cursorHoverText(homeBtn)
}

//////---------------------------------------MENU---------------------------------------//////
function hideMenuAnimation() {
  if (window.matchMedia("(max-width: 767px)").matches) {
    gsap.to('.menuReveal .linkWrapper--2 .link', .75, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', transform: 'translateY(-125px)', stagger: { each: 0.05, from: 'start' }, ease: 'power3.in' })
    gsap.to('.menuReveal .linkWrapper--1 .media', .75, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', transform: 'translateY(-125px)', stagger: { each: 0.025, from: 'start' }, ease: 'power3.in' })
    gsap.to('.menuReveal .linkWrapper--2 .link', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(125px)', delay: 1.25 })
    gsap.to('.menuReveal .linkWrapper--1 .media', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(125px)', delay: 1.25 })
  }
  else {
    gsap.to('.menuReveal .linkWrapper--2 .link', .75, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', transform: 'translateY(-250px)', stagger: { each: 0.05, from: 'start' }, ease: 'power3.in' })
    gsap.to('.menuReveal .linkWrapper--1 .media', .75, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', transform: 'translateY(-250px)', stagger: { each: 0.025, from: 'start' }, ease: 'power3.in' })
    gsap.to('.menuReveal .linkWrapper--2 .link', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(250px)', delay: 1.25 })
    gsap.to('.menuReveal .linkWrapper--1 .media', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(250px)', delay: 1.25 })
  }
}

function openMenu() {
  if (switchSection == "home") {

    gsap.to(ringMaterial, .75, { opacity: 0, ease: 'power3' })
    gsap.to(logoMaterial, .75, { opacity: 0, ease: 'power3' })

    gsap.to('.container--home', .75, { opacity: 0, ease: 'power3' })
    gsap.to('.container--home', 0, { transform: 'scale(1.17)', delay: .75 })
    gsap.to('.homeLink', .75, { opacity: 0, ease: 'power3' })

    setTimeout(function () {
      scene.remove(ringGroup, logo)
      gsap.to('.menuReveal .linkWrapper--2 .link', 1, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'translateY(0)', stagger: 0.05, ease: 'power3' })
      gsap.to('.menuReveal .linkWrapper--1 .media', 1, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'translateY(0)', stagger: 0.025, ease: 'power3' })
    }, 750)
  }

  if (switchSection == "work") {
    cancelOpacityScrollAnimation = true

    gsap.to('.projectName', .75, { opacity: 0, ease: 'power3' })
    gsap.to('.projectCat', .75, { opacity: 0, ease: 'power3' })
    gsap.to('.projectName', 0, { transform: 'scale(1.17)', delay: .75 })
    gsap.to('.projectCat', 0, { transform: 'scale(1.17)', delay: .75 })

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
      gsap.to(ringMaterial, .75, { opacity: 0, ease: 'power3' })
      setTimeout(function(){
        scene.remove(ringGroup)
      }, 750)
    } else {
      setTimeout(function(){
        scene.remove(cylinderWorkMesh)
      }, 750)
      gsap.to(cylinderWorkMaterial.uniforms.u_alpha, .75, { value: 0.0, ease: "power3" })
      gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
      gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
      gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
      gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
      gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
      gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
      gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
      gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
      gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
      gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
      gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
      gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
      gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
      gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
      gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
      gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
      gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
      gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
      gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
      gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    }

    setTimeout(function () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = alphaTexture
        cylinderWorkMaterial.uniforms.u_texture2.value = idTexture

        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, .75, { value: 0.0, ease: "power3" })
      }

      gsap.to('.menuReveal .linkWrapper--2 .link', 1, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'translateY(0)', stagger: 0.05, ease: 'power3' })
      gsap.to('.menuReveal .linkWrapper--1 .media', 1, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'translateY(0)', stagger: 0.025, ease: 'power3' })

      project.forEach(p => {
        p.style.display = 'none'
      })
    }, 750)
  }

  if (switchSection == "about") {
    cancelOpacityScrollAnimation = true

    gsap.to(ringMaterial, .75, { opacity: 0, ease: 'power3' })
    gsap.to('.container--about .title', .75, { opacity: 0, ease: 'power3' })
    gsap.to('.container--about .aboutWrapper', .75, { opacity: 0, ease: 'power3' })
    gsap.to('.container--about .scrollIndication', .75, { opacity: 0, ease: 'power3' })
    gsap.to('.container--about .title', 0, { transform: 'scale(1.17)', delay: .75 })
    gsap.to('.container--about .aboutWrapper', 0, { transform: 'scale(1.17)', delay: .75 })

    setTimeout(function () {
      scene.remove(ringGroup, logo)
      gsap.to('.menuReveal .linkWrapper--2 .link', 1, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'translateY(0)', stagger: 0.05, ease: 'power3' })
      gsap.to('.menuReveal .linkWrapper--1 .media', 1, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'translateY(0)', stagger: 0.025, ease: 'power3' })
    }, 750)
  }

  if (switchSection == "home" || switchSection == "about" || switchSection == "work") {
    locoScroll.stop()
    lineContainer__1.classList.add('switch')
    hoverLine__1.classList.add('switch')
    hoverLine__2.classList.add('switch')
    hamburgerWrapper.style.pointerEvents = "none"
    homeBtn.forEach(e => {
      e.style.pointerEvents = 'none'
    })
    menu.style.zIndex = '1'
    menu.style.pointerEvents = 'all'
    switchCursorIndication = true
    gsap.to(camera.position, .75, { z: 1, delay: .5 })

    setTimeout(function () {
      locoScroll.scrollTo('top', 0)
      locoScroll.update()
      ScrollTrigger.refresh()

      hoverCrossLine__1.classList.add('switch')
      hoverCrossLine__2.classList.add('switch')
      crossLine__1.classList.add('switch')
      crossLine__2.classList.add('switch')
      lineContainer__3.classList.add('switch')
      lineContainer__4.classList.add('switch')

      setTimeout(function () {
        hamburgerWrapper.style.pointerEvents = "all"
        switchMenu = true
      }, 700)
    }, 750)
  }

  if (switchSection == "workReveal") {
    setTimeout(function () {
      lineContainer__1.classList.add('switch')
      hoverLine__1.classList.add('switch')
      hoverLine__2.classList.add('switch')
      allCrossLine.forEach(e => {
        e.classList.add('switchHeight')
      })
      allHoverCrossLine.forEach(e => {
        e.classList.add('switchHeight')
      })
    }, 750)

    hamburgerWrapper.style.pointerEvents = "none"

    setTimeout(function () {
      crossLine__1.classList.add('switch')
      crossLine__2.classList.add('switch')
      crossLine__2.classList.add('switch')
      hoverCrossLine__1.classList.add('switch')
      hoverCrossLine__2.classList.add('switch')
      lineContainer__3.classList.add('switch')
      lineContainer__4.classList.add('switch')

      scene.remove(cylinderWorkMesh)

      setTimeout(function () {
        hamburgerWrapper.style.pointerEvents = "all"
        switchMenu = true
      }, 2500)
    }, 2250)
  }

  if (switchSection == "showreel") {
    openShowreel()
  }
}

function closeMenu() {
  locoScroll.stop()

  if (switchSection == "home") {
    scene.add(ringGroup, logo)

    setTimeout(function () {
      gsap.to(ringMaterial, 1.5, { opacity: .15, ease: 'power3' })
      gsap.to(logoMaterial, 1.5, { opacity: 1, ease: 'power3' })
      gsap.to('.container--home', 1.5, { opacity: 1, transform: 'scale(1)', ease: 'power3' })
      gsap.to('.homeLink', 1, { opacity: .6, ease: 'power3', delay: .55 })

      homeContainer.style.display = "flex"
      aboutContainer.style.display = "none"
      workContainer.style.display = "none"

      setTimeout(function () {
        homeBtn.forEach(e => {
          e.style.pointerEvents = 'all'
        })
      }, 750)
    }, 1000)
  }

  if (switchSection == "about") {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
      gsap.to('.heySection span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(50px)' })
      gsap.to('.container--about .title--about span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(125px) rotateX(-90deg) rotateY(-45deg) rotateZ(-45deg)' })
      gsap.to('.aboutWrapper--2 p', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(25px)', opacity: 0 })
      gsap.to('.container--about .title--course span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(125px) rotateX(-90deg) rotateY(-45deg) rotateZ(-45deg)' })
      gsap.to('.courseSection--1 p', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(25px)', opacity: 0 })
      gsap.to('.courseSection--2 p', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(25px)', opacity: 0 })
      gsap.to('.courseSection--3 p', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(25px)', opacity: 0 })
      gsap.to('.container--about .title--skills span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(125px) rotateX(-90deg) rotateY(-45deg) rotateZ(-45deg)' })
      gsap.to('.skillsSection--1 span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(125px)' })
      gsap.to('.skillsSection--1 li', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(25px)' })
      gsap.to('.skillsSection--2 span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(125px)' })
      gsap.to('.skillsSection--2 li', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(25px)' })
      gsap.to('.skillsSection--3 span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(125px)' })
      gsap.to('.skillsSection--3 li', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(25px)' })
    } else {
      gsap.to('.heySection span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(250px)' })
      gsap.to('.container--about .title--about span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(250px) rotateX(-90deg) rotateY(-45deg) rotateZ(-45deg)' })
      gsap.to('.aboutWrapper--2 p', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(50px)', opacity: 0 })
      gsap.to('.container--about .title--course span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(250px) rotateX(-90deg) rotateY(-45deg) rotateZ(-45deg)' })
      gsap.to('.courseSection--1 p', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(50px)', opacity: 0 })
      gsap.to('.courseSection--2 p', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(50px)', opacity: 0 })
      gsap.to('.courseSection--3 p', 0, { clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(50px)', opacity: 0 })
      gsap.to('.container--about .title--skills span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(250px) rotateX(-90deg) rotateY(-45deg) rotateZ(-45deg)' })
      gsap.to('.skillsSection--1 span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(250px)' })
      gsap.to('.skillsSection--1 li', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(50px)' })
      gsap.to('.skillsSection--2 span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(250px)' })
      gsap.to('.skillsSection--2 li', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(50px)' })
      gsap.to('.skillsSection--3 span', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(250px)' })
      gsap.to('.skillsSection--3 li', 0, { clipPath: 'inset(0% 0% 100% 0%)', opacity: 0, transform: 'translateY(50px)' })
    }

    scene.add(ringGroup, logo)

    allImg.forEach(img => {
      img.style.pointerEvents = 'all'
    })

    setTimeout(function () {
      gsap.to(ringMaterial, 1.5, { opacity: .15, ease: 'power3' })
      gsap.to('.container--about .title', 1.5, { opacity: 1, transform: 'scale(1)', ease: 'power3' })
      gsap.to('.container--about .aboutWrapper', 1.5, { opacity: 1, transform: 'scale(1)', ease: 'power3' })
      gsap.to('.container--about .scrollIndication', 1, { opacity: 1, ease: 'power3', delay: .55 })

      setTimeout(function () {
        locoScroll.start()
        cancelOpacityScrollAnimation = false
      }, 700)

      homeContainer.style.display = "none"
      aboutContainer.style.display = "flex"
      workContainer.style.display = "none"
    }, 1000)
  }

  if (switchSection == "work") {
    project.forEach(p => {
      p.style.display = 'flex'
    })

    scene.add(cylinderWorkMesh)

    revealWork = false

    setTimeout(function () {
      gsap.to('.projectName', 1.5, { opacity: 1, transform: 'scale(1)', ease: 'power3' })
      gsap.to('.projectCat', 1.5, { opacity: 1, transform: 'scale(1)', ease: 'power3' })
      gsap.to('.projectName .mask--1 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
      setTimeout(function () {
        locoScroll.start()
      }, 300)

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
        gsap.to(ringMaterial, 1.5, { opacity: .15, ease: 'power3' })
        scene.add(ringGroup)
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = alphaTexture
        cylinderWorkMaterial.uniforms.u_texture2.value = idTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1.5, { value: 1.0, ease: "power3" })
        gsap.to(cylinderWorkMaterial.uniforms.u_alpha, 1.5, { value: 0.35, ease: "power3" })
      }

      cancelOpacityScrollAnimation = false

      homeContainer.style.display = "none"
      aboutContainer.style.display = "none"
      workContainer.style.display = "flex"
      
      scene.remove(logo)
    }, 1000)
    setTimeout(() => {
      revealWork = true
    }, 1750)
  }

  if (switchSection == "home" || switchSection == "about" || switchSection == "work") {
    hideMenuAnimation()

    switchCursorIndication = false

    menu.style.pointerEvents = 'none'

    lineContainer__3.classList.remove('switch')
    lineContainer__4.classList.remove('switch')
    crossLine__1.classList.remove('switch')
    crossLine__2.classList.remove('switch')
    hoverCrossLine__1.classList.remove('switch')
    hoverCrossLine__2.classList.remove('switch')
    hamburgerWrapper.style.pointerEvents = "none"

    setTimeout(function () {
      lineContainer__1.classList.remove('switch')
      hoverLine__1.classList.remove('switch')
      hoverLine__2.classList.remove('switch')

      locoScroll.update()
      ScrollTrigger.refresh()

      setTimeout(function () {
        menu.style.zIndex = '-1'
        hamburgerWrapper.style.pointerEvents = "all"
        switchMenu = false
      }, 700)

      gsap.to(camera.position, 1.5, { z: 1.5, ease: 'power3' })
    }, 1000)
  }

  if (switchSection == "workReveal") {
    switchSection = "work"

    scene.add(cylinderWorkMesh)

    gsap.to(sphereWireframeMaterial, 1.5, { opacity: .015, ease: 'power3', delay: 1.25 })
    gsap.to(sphereWireframeMaterial.color, 1.5, { r: sphereColor.r, g: sphereColor.g, b: sphereColor.b, ease: 'power3', delay: 1.25 })

    revealWork = false

    allImg.forEach(img => {
      img.style.pointerEvents = 'none'
    })

    gsap.to('.workRevealContainer', .75, { opacity: 0, ease: 'power3' })

    lineContainer__3.classList.remove('switch')
    lineContainer__4.classList.remove('switch')
    crossLine__1.classList.remove('switch')
    crossLine__2.classList.remove('switch')
    hoverCrossLine__1.classList.remove('switch')
    hoverCrossLine__2.classList.remove('switch')

    allCrossLine.forEach(e => {
      e.classList.remove('switchHeight')
    })

    allHoverCrossLine.forEach(e => {
      e.classList.remove('switchHeight')
    })

    hamburgerWrapper.style.pointerEvents = "none"

    setTimeout(function () {
      setTimeout(function () {
        lineContainer__1.classList.remove('switch')
        hoverLine__1.classList.remove('switch')
        hoverLine__2.classList.remove('switch')
        switchMenu = false
        setTimeout(function () {
          hamburgerWrapper.style.pointerEvents = "all"
        }, 1250)
      }, 700)

      locoScroll.update()
      ScrollTrigger.refresh()

      setTimeout(function () {
        revealWork = true
        locoScroll.start()
        cancelOpacityScrollAnimation = false
      }, 2000)

      setTimeout(function () {
        audioSwitcher.color = '#b1b1b1'
        gsap.to('.musicWrapper', .1, { opacity: 0, ease: 'power3' })
        gsap.to('.musicWrapper', .1, { opacity: 1, ease: 'power3', delay: .1 })
      }, 210)

      gsap.to('.projectName', 1.5, { opacity: 1, transform: 'scale(1)', ease: 'power3', delay: 1.25 })
      gsap.to('.projectCat', 1.5, { opacity: 1, transform: 'scale(1)', ease: 'power3', delay: 1.25 })

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
        gsap.to(ringMaterial, 1.5, { opacity: .15, ease: 'power3', delay: 1.25 })
        gsap.to('.workRevealContainer .titleProject .title--2', 0, { opacity: 0, clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(75px)' })
        gsap.to('.workRevealContainer .linkProject a', 0, { opacity: 0, clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(25px)' })
        gsap.to(paraWorkReveal, 0, { transform: 'translateY(75px)', opacity: 0, clipPath: 'inset(0% 0% 100% 0%)' })
        gsap.to('.workRevealWrapper .section--2 img', 0, { opacity: 0, clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(75px)' })
      } else {
        gsap.to(cylinderWorkMaterial.uniforms.u_alpha, 1.5, { value: 0.35, ease: "power3", delay: .5 })
        gsap.to(cylinderWorkMaterial.uniforms.u_mouseIntensity, 1.5, { value: 0.01, ease: "power3", delay: .5 })
        gsap.to(cylinderWorkMaterial.uniforms.u_mouseRange, 1.5, { value: 20.0, ease: "power3", delay: .5 })
        gsap.to('.workRevealContainer .titleProject .title--2', 0, { opacity: 0, clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(150px)' })
        gsap.to('.workRevealContainer .linkProject a', 0, { opacity: 0, clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(50px)' })
        gsap.to(paraWorkReveal, 0, { transform: 'translateY(150px)', opacity: 0, clipPath: 'inset(0% 0% 100% 0%)' })
        gsap.to('.workRevealWrapper .section--2 img', 0, { opacity: 0, clipPath: 'inset(0% 0% 100% 0%)', transform: 'translateY(150px)' })
      }

      gsap.to(workRevealMask, 0, { clipPath: 'inset(0% 0% 100% 0%)' })
      gsap.to(workRevealMask, 0, { clipPath: 'inset(100% 0% 0% 0%)', delay: 1.25 })

      switchWorkTitle.forEach(t => {
        t.innerHTML = ""
      })

      paraWorkReveal.innerHTML = ""

      linkProjectWorkReveal.innerHTML = ""

      workRevealContainer.style.display = "none"

      gsap.to('.workRevealContainer .title--1', 0, { transform: 'scale(1.17)' })
    }, 750)
  }

  if (switchSection == 'showreel') {
    closeShowreel()
  }
}

hamburgerWrapper.addEventListener('click', () => {
  if (!switchMenu) {
    openMenu()
  }
  else {
    closeMenu()
  }
})

homeMenuBtn.addEventListener('click', () => {
  if (switchMenu == true) {
    homeContainer.style.display = "flex"
    aboutContainer.style.display = "none"
    workContainer.style.display = "none"
    switchHomeToSection = false

    setTimeout(function () {
      switchHomeToSection = true
    }, 1500)

    switchSection = "home"

    closeMenu()

    locoScroll.update()
    ScrollTrigger.refresh()
  }
})

aboutMenuBtn.addEventListener('click', () => {
  if (switchMenu == true) {
    homeContainer.style.display = "none"
    aboutContainer.style.display = "flex"
    workContainer.style.display = "none"

    gsap.to('.container--about .title', 0, { transform: 'scale(1.17)' })
    gsap.to('.container--about .aboutWrapper', 0, { transform: 'scale(1.17)' })

    switchSection = "about"

    closeMenu()

    locoScroll.update()
    ScrollTrigger.refresh()
  }
})

homeBtnAbout.addEventListener('click', () => {
  if (switchHomeToSection == true) {
    gsap.to(logoMaterial, .75, { opacity: 0, ease: 'power3' })
    gsap.to('.container--home', .75, { opacity: 0, ease: 'power3' })
    gsap.to('.container--home', 0, { transform: 'scale(1.17)', delay: .75 })
    gsap.to('.homeLink', .75, { opacity: 0, ease: 'power3' })
    gsap.to('.container--about .title', 0, { transform: 'scale(1.17)' })
    gsap.to('.container--about .aboutWrapper', 0, { transform: 'scale(1.17)' })

    hamburgerWrapper.style.pointerEvents = "none"
    homeBtn.forEach(e => {
      e.style.pointerEvents = 'none'
    })

    switchSection = "about"

    closeMenu()
    locoScroll.update()
    ScrollTrigger.refresh()

    mousePressedActive = false

    setTimeout(function () {
      mousePressedActive = true
    }, 1700)
  }
})

workMenuBtn.addEventListener('click', () => {
  if (switchMenu == true) {
    homeContainer.style.display = "none"
    aboutContainer.style.display = "none"
    workContainer.style.display = "flex"

    gsap.to('.projectName', 0, { transform: 'scale(1.17)' })
    gsap.to('.projectCat', 0, { transform: 'scale(1.17)' })

    switchSection = "work"

    closeMenu()

    setTimeout(() => {
      revealWork = true
    }, 1750)

    locoScroll.update()
    ScrollTrigger.refresh()
  }
})

homeBtnWork.addEventListener('click', () => {
  if (switchHomeToSection == true) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
    }
    else {
      gsap.to(ringMaterial, .75, { opacity: 0, ease: 'power3' })
    }
    gsap.to(logoMaterial, .75, { opacity: 0, ease: 'power3' })

    hamburgerWrapper.style.pointerEvents = "none"
    homeBtn.forEach(e => {
      e.style.pointerEvents = 'none'
    })

    gsap.to('.container--home', .75, { opacity: 0, ease: 'power3' })
    gsap.to('.container--home', 0, { transform: 'scale(1.17)', delay: .75 })
    gsap.to('.homeLink', .75, { opacity: 0, ease: 'power3' })

    gsap.to('.projectName', 0, { transform: 'scale(1.17)' })
    gsap.to('.projectCat', 0, { transform: 'scale(1.17)' })

    switchSection = "work"

    closeMenu()

    mousePressedActive = false

    setTimeout(() => {
      revealWork = true
      mousePressedActive = true
    }, 1750)

    locoScroll.update()
    ScrollTrigger.refresh()
  }
})

homeBtnShowreel.addEventListener('click', () => {
  gsap.to(logoMaterial, .75, { opacity: 0, ease: 'power3' })
  gsap.to(ringMaterial, .75, { opacity: 0, ease: 'power3' })

  gsap.to('.container--home', .75, { opacity: 0, ease: 'power3' })
  gsap.to('.container--home', 0, { transform: 'scale(1.17)', delay: .75 })
  gsap.to('.homeLink', .75, { opacity: 0, ease: 'power3' })
  gsap.to('.musicWrapper', .75, { opacity: 0, ease: 'power3' })

  switchSection = "showreel"

  openMenu()
})

showreelMenuBtn.addEventListener('click', () => {
  if (switchMenu == true) {
    menu.style.pointerEvents = 'none'
    switchSection = "showreel"

    hideMenuAnimation()

    gsap.to('.musicWrapper', .75, { opacity: 0, ease: 'power3' })

    setTimeout(function () {
      openMenu()
    }, 500)
  }
})

video.addEventListener('ended', () => {
  closeShowreel()
})

video.onclick = function () {
  if (video.paused) {
    video.play()
  } else {
    video.pause()
  }
}

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
} else {
  menuBtn.forEach(b => {
    b.addEventListener('mouseenter', () => {
      gsap.to(customCursorDot, .5, { opacity: 0, ease: 'power3' })
      gsap.to(customCursor, .5, { opacity: 1, padding: '45px', borderRadius: '100%', ease: 'power3' })
      gsap.to(customCursorIndication, .5, { opacity: 0, ease: 'power3' })
      gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 1)', ease: 'power3' })
      gsap.to(customCursorFollower, .5, { opacity: 0, padding: '55px', borderRadius: '100%', ease: 'power3' })
    })
    b.addEventListener('mouseleave', () => {
      gsap.to(customCursorDot, .5, { opacity: 1, ease: 'power3.in' })
      gsap.to(customCursor, .5, { opacity: 1, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
      gsap.to(customCursorIndication, .5, { opacity: 1, ease: 'power3.in' })
      gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
      gsap.to(customCursorFollower, .5, { opacity: .5, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
    })
    b.addEventListener('click', () => {
      if (switchMenu == true) {
        gsap.to(customCursorDot, .5, { opacity: 1, ease: 'power3.in' })
        gsap.to(customCursor, .5, { opacity: 1, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
        gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
        gsap.to(customCursorFollower, .5, { opacity: .5, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
      }
    })
  })

  homeMenuBtn.addEventListener('mouseenter', () => {
    gsap.to('.link .mask--home .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
  })

  homeMenuBtn.addEventListener('mouseleave', () => {
    gsap.to('.link .mask--home .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
  })

  aboutMenuBtn.addEventListener('mouseenter', () => {
    gsap.to('.link .mask--about .char', .5, { opacity: 0, clipPath: 'inset(0% 0% 100% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
  })

  aboutMenuBtn.addEventListener('mouseleave', () => {
    gsap.to('.link .mask--about .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
  })

  workMenuBtn.addEventListener('mouseenter', () => {
    gsap.to('.link .mask--work .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
  })

  workMenuBtn.addEventListener('mouseleave', () => {
    gsap.to('.link .mask--work .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
  })

  showreelMenuBtn.addEventListener('mouseenter', () => {
    gsap.to('.link .mask--showreel .char', .5, { opacity: 0, clipPath: 'inset(0% 0% 100% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
  })

  showreelMenuBtn.addEventListener('mouseleave', () => {
    gsap.to('.link .mask--showreel .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
  })

  cursorHoverText(media)
}

//////WORK//////
project.forEach(e => {
  e.addEventListener('mouseover', () => {
    gsap.to(customCursorDot, .5, { opacity: 0, ease: 'power3' })
    gsap.to(customCursor, .5, { opacity: 1, padding: '45px', borderRadius: '100%', ease: 'power3' })
    gsap.to(customCursorIndication, .5, { opacity: 0, ease: 'power3' })
    gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 1)', ease: 'power3' })
    gsap.to(customCursorFollower, .5, { opacity: 0, padding: '55px', borderRadius: '100%', ease: 'power3' })
  })

  e.addEventListener('mouseleave', () => {
    gsap.to(customCursorDot, .5, { opacity: 1, ease: 'power3.in' })
    gsap.to(customCursor, .5, { opacity: 1, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
    gsap.to(customCursorIndication, .5, { opacity: 1, ease: 'power3.in' })
    gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
    gsap.to(customCursorFollower, .5, { opacity: .5, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
  })
  e.addEventListener('click', () => {
    if (revealWork == true) {
      gsap.to(customCursorDot, .5, { opacity: 1, ease: 'power3.in' })
      gsap.to(customCursor, .5, { opacity: 1, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
      gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
      gsap.to(customCursorFollower, .5, { opacity: .5, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
    }
  })
})

function openWork() {
  switchSection = "workReveal"

  if (revealWork == true) {
    openMenu()

    locoScroll.stop()

    allImg.forEach(img => {
      img.style.pointerEvents = 'all'
    })

    cancelOpacityScrollAnimation = true

    switch (switchWork) {
      case 'id2021':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "ID 2021"
        })
        paraWorkReveal.innerHTML = "Immersions Digitales 2021 is a WebGl experience created in order to introduce the open days of the year from my technical faculty in Tarbes (France). We were three to work on this project, including a 3D modeler and two developers. As a developer, I imagined and developed a large part of 2D/3D animations and the micro interactions. We were awarded by an honorable mention on Awwwards.<br><br>Technologies used :<br>HTML, SCSS, JavaScript, Three.js, GLSL, GSAP"
        imgWorkReveal.src = '/textures/img/idWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.immersions-digitales.fr/" target="_blank">Launch Website</a>'
        break

      case 'astralys':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "ASTRALYS"
        })
        paraWorkReveal.innerHTML = "Astralys is a WebGl experience originally created as part of a university project. Its purpose is to show our solar system on scale, with several informations on the different elements that compose it. I was in charge of developing the whole website.<br><br>Technologies used :<br>HTML, CSS, JavaScript, Three.js"
        imgWorkReveal.src = '/textures/img/astralysWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://astralys.thomalecornu.fr/" target="_blank">Launch Website</a>'
        break

      case 'terreDeBois':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "TERRE DE BOIS"
        })
        paraWorkReveal.innerHTML = "Proposal of a new corporate identity for a company named Terre de Bois. This corporation is  a cooperative of eco-builders with 20 years of expertise in construction industry.<br><br>Software used :<br>Adobe Illustrator"
        imgWorkReveal.src = '/textures/img/terreDeBoisWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114896841/Terre-de-Bois" target="_blank">See on Behance</a>'
        break

      case 'auriane':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "AURIANE"
        })
        paraWorkReveal.innerHTML = "Portfolio created in two days for a friend. Through this project, I sought to reproduce minimalists design and animations. Layout inspired by Paul & Henriette website from Aristide Benoist.<br><br>Technologies used :<br>HTML, SCSS, JavaScript, Locomotive Scroll"
        imgWorkReveal.src = '/textures/img/aurianeWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://auriane.thomalecornu.fr/" target="_blank">Launch Website</a>'
        break

      case 'cmsFolio':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "CMS FOLIO"
        })
        paraWorkReveal.innerHTML = "CMS folio is a web development project with a content management system. The website uses a database to alter and add dynamically the content of each project.</br></br>Technologies used :</br>PHP, SQL, HTML, CSS"
        imgWorkReveal.src = '/textures/img/cmsFolioWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://cmsfolio.thomalecornu.fr/" target="_blank">Launch Website</a>'
        break

      case 'newspaper':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "NEWSPAPER"
        })
        paraWorkReveal.innerHTML = "Newspaper is a 3D motion design video. The purpose was to create in group, a newspaper of one week, in any support of our choice. I imagined and created the video, inspired by BBC.</br></br>Softwares used :</br>Adobe After Effects, Trapcode Particular"
        imgWorkReveal.src = '/textures/img/newspaperWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114896361/Newspaper" target="_blank">Watch on Behance</a>'
        break

      case 'jamOn':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "JAM-ON"
        })
        paraWorkReveal.innerHTML = "JAM-ON is a web model created for a graphic design project. The purpose was to create a graphic identity for a jam website, targeting a population under 25 years old.<br><br>Software used :<br>Adobe XD"
        imgWorkReveal.src = '/textures/img/jamOnWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114896117/JAM-ON" target="_blank">See on Behance</a>'
        break

      case 'folio2020':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "FOLIO 2020"
        })
        paraWorkReveal.innerHTML = "This is my personal portfolio for 2020. Layout inspired by Richard Mattka's portfolio.</br></br>Technologies used :</br>HTML, CSS, JavaScript, PHP"
        imgWorkReveal.src = '/textures/img/folio2020Work.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://folio2020.thomalecornu.fr/" target="_blank">Launch Website</a>'
        break

      case 'internship':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "INTERNSHIP"
        })
        paraWorkReveal.innerHTML = "At the end of my first year internship at GSP Publicité, we had to do a video of presentation. I chosed motion design, because in my opinion, this is what illustrates the best the company and the tasks they are performing.<br><br>Software used :<br>Adobe After Effects"
        imgWorkReveal.src = '/textures/img/internshipWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114895861/Internship" target="_blank">Watch on Behance</a>'
        break

      case 'gamovore':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "GAMOVORE"
        })
        paraWorkReveal.innerHTML = "Gamovore is a dynamic web project. The goal was to create an encyclopedia, which references video games by categories and classes with a rating.</br></br>Technologies used :</br>PHP, HTML, CSS"
        imgWorkReveal.src = '/textures/img/gamovoreWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://gamovore.thomalecornu.fr/" target="_blank">Launch Website</a>'
        break

      case 'dresson':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "DRESSON"
        })
        paraWorkReveal.innerHTML = "DressOn is a web model produced during a project management course. The objective was to create a brand image of our choice with a graphic identity of its own.<br><br>Software used :<br>Adobe XD"
        imgWorkReveal.src = '/textures/img/dressOnWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114895709/DressOn" target="_blank">See on Behance</a>'
        break

      case 'mmorpion':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "MMORPION"
        })
        paraWorkReveal.innerHTML = "MMORPION is a dynamic web project. The aim was to recreate the famous game of noughts and crosses in PHP.</br></br>Technologies used :</br>PHP, HTML, CSS"
        imgWorkReveal.src = '/textures/img/mmorpionWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://mmorpion.thomalecornu.fr/" target="_blank">Launch Website</a>'
        break

      case 'mushrooms':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "MUSHROOMS"
        })
        paraWorkReveal.innerHTML = "Accomplishment of several mushrooms inspired by the famous game Mario. We had to do three, with the character design of our choice.<br><br>Software used :<br>Adobe Illustrator"
        imgWorkReveal.src = '/textures/img/mushroomsWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114895327/Mushrooms" target="_blank">See on Behance</a>'
        break

      case 'yasuo':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "YASUO"
        })
        paraWorkReveal.innerHTML = "Yasuo is a 3D animation made during my free time.<br><br>Softwares used :<br>Adobe After Effects, Trapcode Particular, Plexus"
        imgWorkReveal.src = '/textures/img/3dBackgroundWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114895183/Yasuo" target="_blank">Watch on Behance</a>'
        break

      case 'folio2019':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "FOLIO 2019"
        })
        paraWorkReveal.innerHTML = "This is my personal portfolio for 2019.</br></br>Technologies used :</br>HTML, CSS"
        imgWorkReveal.src = '/textures/img/folio2019Work.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="http://folio2019.thomalecornu.fr/" target="_blank">Launch Website</a>'
        break

      case 'laDette':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "LA DETTE"
        })
        paraWorkReveal.innerHTML = "Group work as part of a communication course, we had to do a multimedia content to explain the appearance of debt. I made the video entirely in motion design to make the subject as clear as possible.<br><br>Softwares used :<br>Adobe After Effects, Trapcode Particular"
        imgWorkReveal.src = '/textures/img/laDetteWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114895015/Aux-origines-de-la-dette" target="_blank">Watch on Behance</a>'
        break

      case 'animayo':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "ANIMAYO"
        })
        paraWorkReveal.innerHTML = "Animayo is an animation, visual effects and video game festival. I made this design as part of a spanish class, for the dream-themed illustration contest.<br><br>Software used :<br>Adobe Illustrator"
        imgWorkReveal.src = '/textures/img/animayoWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114894841/Animayo" target="_blank">See on Behance</a>'
        break

      case 'outroId':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "OUTRO ID"
        })
        paraWorkReveal.innerHTML = "Outro ID is an animation produced for the Immersions Digitales 2020 teaser and the TV studio workshop.<br><br>Softwares used :<br>Adobe After Effects, Trapcode Particular"
        imgWorkReveal.src = '/textures/img/outroIdWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114894651/Outro-ID" target="_blank">Watch on Behance</a>'
        break

      case 'letterMask':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "LETTER MASK"
        })
        paraWorkReveal.innerHTML = "Letter mask is an illustration made for a design class. The goal was to inlay a character in a letter, using layers masks.<br><br>Software used :<br>Adobe Photoshop"
        imgWorkReveal.src = '/textures/img/letterMaskWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114894027/Letter-mask" target="_blank">See on Behance</a>'
        break

      case 'motionIntro':
        switchWorkTitle.forEach(t => {
          t.innerHTML = "MOTION INTRO"
        })
        paraWorkReveal.innerHTML = "Motion intro is a motion design animation made during my free time.<br><br>Software used :<br>Adobe After Effects"
        imgWorkReveal.src = '/textures/img/motionIntroWork.jpg'
        linkProjectWorkReveal.innerHTML = '<a href="https://www.behance.net/gallery/114893481/Motion-intro" target="_blank">Watch on Behance</a>'
        break
    }

    let linkProject = document.querySelectorAll('.workRevealContainer .linkProject a')

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
      gsap.to(ringMaterial, .75, { opacity: 0, ease: 'power3' })
    } else {
      gsap.to(cylinderWorkMaterial.uniforms.u_mouseIntensity, 5, { value: .25, ease: "power3" })
      gsap.to(cylinderWorkMaterial.uniforms.u_mouseRange, 5, { value: 100.0, ease: "power3" })
      gsap.to(cylinderWorkMaterial.uniforms.u_alpha, .75, { value: 0.0, ease: "power3", delay: .75 })

      cursorHoverText(linkProject)
    }

    workRevealContainer.style.display = "flex"

    gsap.to('.projectName', .75, { opacity: 0, ease: 'power3' })
    gsap.to('.projectCat', .75, { opacity: 0, ease: 'power3' })
    gsap.to('.workRevealContainer', 0, { opacity: 1, ease: 'power3' })

    setTimeout(function () {
      gsap.to('.projectName', 0, { transform: 'scale(1.17)' })
      gsap.to('.projectCat', 0, { transform: 'scale(1.17)' })

      setTimeout(function () {
        audioSwitcher.color = "#151617"
        gsap.to('.musicWrapper', .1, { opacity: 0, ease: 'power3' })
        gsap.to('.musicWrapper', .1, { opacity: 1, ease: 'power3', delay: .1 })
      }, 210)

      gsap.to('.workRevealContainer .title--1', 1.5, { opacity: 1, transform: 'scale(1)', ease: 'power3', delay: .5 })
      gsap.to('.workRevealContainer .title--1', 1.5, { opacity: 0, ease: 'power3', delay: 1.25 })
      gsap.to('.workRevealContainer .titleProject .title--2', 1, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'translateY(0)', ease: 'power3', delay: 2.25 })
      gsap.to(paraWorkReveal, 1, { opacity: 1, transform: 'translateY(0)', clipPath: 'inset(0% 0% 0% 0%)', ease: 'power3', delay: 2.5 })
      gsap.to('.workRevealWrapper .section--2 img', 1, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'translateY(0)', ease: 'power3', delay: 2.5 })
      gsap.to('.workRevealContainer .linkProject a', 1, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'translateY(0)', ease: 'power3', delay: 2.75 })
      gsap.to(workRevealMask, 0, { clipPath: 'inset(0% 0% 0% 0%)' })

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
        gsap.to(sphereWireframeMaterial, 1.5, { opacity: .025, ease: 'power3', delay: .25 })
        gsap.to(sphereWireframeMaterial.color, 1.5, { r: blackColor.r, g: blackColor.g, b: blackColor.b, ease: 'power3', delay: .25 })
      } else {
        gsap.to(sphereWireframeMaterial, 1.5, { opacity: .035, ease: 'power3', delay: .25 })
        gsap.to(sphereWireframeMaterial.color, 1.5, { r: blackColor.r, g: blackColor.g, b: blackColor.b, ease: 'power3', delay: .25 })
      }
    }, 1500)
  }
}

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {

} else {
  faceImg.addEventListener('mousemove', () => {
    gsap.to(customCursorDot, .5, { opacity: 0, ease: 'power3' })
    gsap.to(customCursor, .5, { opacity: 1, padding: '45px', borderRadius: '100%', ease: 'power3' })
    gsap.to(customCursorIndication, .5, { opacity: 0, ease: 'power3' })
    gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 1)', ease: 'power3' })
    gsap.to(customCursorFollower, .5, { opacity: 0, padding: '55px', borderRadius: '100%', ease: 'power3' })
  })

  faceImg.addEventListener('mouseleave', () => {
    gsap.to(customCursorDot, .5, { opacity: 1, ease: 'power3.in' })
    gsap.to(customCursor, .5, { opacity: 1, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
    gsap.to(customCursorIndication, .5, { opacity: 1, ease: 'power3.in' })
    gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
    gsap.to(customCursorFollower, .5, { opacity: .5, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
  })

  imgWorkReveal.addEventListener('mouseenter', () => {
    gsap.to(customCursorDot, .5, { opacity: 0, ease: 'power3' })
    gsap.to(customCursor, .5, { opacity: 1, padding: '45px', borderRadius: '100%', ease: 'power3' })
    gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 1)', ease: 'power3' })
    gsap.to(customCursorFollower, .5, { opacity: 0, padding: '55px', borderRadius: '100%', ease: 'power3' })
  })

  imgWorkReveal.addEventListener('mouseleave', () => {
    gsap.to(customCursorDot, .5, { opacity: 1, ease: 'power3.in' })
    gsap.to(customCursor, .5, { opacity: 1, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
    gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
    gsap.to(customCursorFollower, .5, { opacity: .5, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
  })

  project1.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != idTexture) {
      currentTexture = idTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = idTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = idTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }


  })

  project2.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != astralysTexture) {
      currentTexture = astralysTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = astralysTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = astralysTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project3.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != terreDeBoisTexture) {
      currentTexture = terreDeBoisTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = terreDeBoisTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = terreDeBoisTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project4.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != aurianeTexture) {
      currentTexture = aurianeTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = aurianeTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = aurianeTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project5.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != cmsFolioTexture) {
      currentTexture = cmsFolioTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = cmsFolioTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = cmsFolioTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project6.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != newspaperTexture) {
      currentTexture = newspaperTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = newspaperTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = newspaperTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project7.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != jamOnTexture) {
      currentTexture = jamOnTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = jamOnTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = jamOnTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project8.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != folio2020Texture) {
      currentTexture = folio2020Texture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = folio2020Texture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = folio2020Texture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project9.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != internshipTexture) {
      currentTexture = internshipTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = internshipTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = internshipTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project10.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != gamovoreTexture) {
      currentTexture = gamovoreTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = gamovoreTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = gamovoreTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project11.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != dressOnTexture) {
      currentTexture = dressOnTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = dressOnTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = dressOnTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project12.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != mmorpionTexture) {
      currentTexture = mmorpionTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = mmorpionTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = mmorpionTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project13.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != mushroomsTexture) {
      currentTexture = mushroomsTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = mushroomsTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = mushroomsTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project14.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != yasuoBackgroundTexture) {
      currentTexture = yasuoBackgroundTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = yasuoBackgroundTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = yasuoBackgroundTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project15.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != folio2019Texture) {
      currentTexture = folio2019Texture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = folio2019Texture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = folio2019Texture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project16.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != laDetteTexture) {
      currentTexture = laDetteTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = laDetteTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = laDetteTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project17.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != animayoTexture) {
      currentTexture = animayoTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = animayoTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = animayoTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project18.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != outroIdTexture) {
      currentTexture = outroIdTexture
      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = outroIdTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = outroIdTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project19.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != letterMaskTexture) {
      currentTexture = letterMaskTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = letterMaskTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = letterMaskTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })

  project20.addEventListener('mouseenter', () => {
    gsap.to('.projectName .mask--1 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--2 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--3 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--4 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--5 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--6 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--7 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--8 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--9 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--10 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--11 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--12 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--13 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--14 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--15 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--16 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--17 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--18 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })
    gsap.to('.projectName .mask--19 .char', .5, { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', stagger: { each: staggerHover, from: 'end' }, ease: 'power3' })
    gsap.to('.projectName .mask--20 .char', .5, { opacity: 0, clipPath: 'inset(100% 0% 0% 0%)', stagger: { each: staggerHover, from: 'start' }, ease: 'power3' })

    if (currentTexture != motionIntroTexture) {
      currentTexture = motionIntroTexture

      if (cylinderWorkMaterial.uniforms.u_dispFactor.value >= .0 && cylinderWorkMaterial.uniforms.u_dispFactor.value <= .5) {
        cylinderWorkMaterial.uniforms.u_texture2.value = motionIntroTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 1.0, ease: "power3" })
      } else {
        cylinderWorkMaterial.uniforms.u_texture1.value = motionIntroTexture
        gsap.to(cylinderWorkMaterial.uniforms.u_dispFactor, 1, { value: 0.0, ease: "power3" })
      }
    }
  })
}

project1.addEventListener('click', () => {
  switchWork = "id2021"
  openWork()
})

project2.addEventListener('click', () => {
  switchWork = "astralys"
  openWork()
})

project3.addEventListener('click', () => {
  switchWork = "terreDeBois"
  openWork()
})

project4.addEventListener('click', () => {
  switchWork = "auriane"
  openWork()
})

project5.addEventListener('click', () => {
  switchWork = "cmsFolio"
  openWork()
})

project6.addEventListener('click', () => {
  switchWork = "newspaper"
  openWork()
})

project7.addEventListener('click', () => {
  switchWork = "jamOn"
  openWork()
})

project8.addEventListener('click', () => {
  switchWork = "folio2020"
  openWork()
})

project9.addEventListener('click', () => {
  switchWork = "internship"
  openWork()
})

project10.addEventListener('click', () => {
  switchWork = "gamovore"
  openWork()
})

project11.addEventListener('click', () => {
  switchWork = "dresson"
  openWork()
})

project12.addEventListener('click', () => {
  switchWork = "mmorpion"
  openWork()
})

project13.addEventListener('click', () => {
  switchWork = "mushrooms"
  openWork()
})

project14.addEventListener('click', () => {
  switchWork = "yasuo"
  openWork()
})

project15.addEventListener('click', () => {
  switchWork = "folio2019"
  openWork()
})

project16.addEventListener('click', () => {
  switchWork = "laDette"
  openWork()
})

project17.addEventListener('click', () => {
  switchWork = "animayo"
  openWork()
})

project18.addEventListener('click', () => {
  switchWork = "outroId"
  openWork()
})

project19.addEventListener('click', () => {
  switchWork = "letterMask"
  openWork()
})

project20.addEventListener('click', () => {
  switchWork = "motionIntro"
  openWork()
})

//////---------------------------------------SHOWREEL---------------------------------------//////

function openShowreel() {
  locoScroll.stop()

  switchSection = 'showreel'

  mousePressed = false

  hamburgerWrapper.style.pointerEvents = "none"
  rotateMobileIndication.classList.add('switch')
  homeBtn.forEach(e => {
    e.style.pointerEvents = 'none'
  })
  audioSwitcher.button.style.pointerEvents = 'none'
  app.style.pointerEvents = 'none'
  menu.style.pointerEvents = 'none'

  gsap.to('.hamburgerWrapper', 0, { opacity: 1, delay: 2 })
  gsap.to(customCursorDot, .5, { opacity: 1, ease: 'power3.in' })
  gsap.to(customCursor, .5, { opacity: 1, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
  gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
  gsap.to('.cursorLoader', .5, { opacity: 0, ease: 'power3.in' })
  gsap.to(customCursorFollower, .5, { opacity: .5, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
  gsap.to('.container--home', 0, { transform: 'scale(1.17)', delay: 2.25 })
  gsap.to(ringMaterial, 0, { opacity: 0, delay: 2.25 })
  gsap.to(logoMaterial, 0, { opacity: 0, delay: 2.25 })

  video.play()

  setTimeout(function () {
    gsap.to('.showreelRevealContainer', 0, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, ease: 'power3' })

    setTimeout(function () {
      lineContainer__1.classList.add('switch')
      hoverLine__1.classList.add('switch')
      hoverLine__2.classList.add('switch')

      bgMusic.fade(0, 0, 1000)
    }, 250)

    setTimeout(function () {
      hoverCrossLine__1.classList.add('switch')
      hoverCrossLine__2.classList.add('switch')
      crossLine__1.classList.add('switch')
      crossLine__2.classList.add('switch')
      lineContainer__3.classList.add('switch')
      lineContainer__4.classList.add('switch')

      menu.style.zIndex = '-1'

      gsap.to(camera.position, 0, { z: 1 })

      scene.remove(ringGroup, logo)

      setTimeout(function () {
        switchMenu = true
        hamburgerWrapper.style.pointerEvents = "all"
      }, 700)
    }, 750)
  }, 1000)
}

function closeShowreel() {
  gsap.to('.showreelRevealContainer', .75, { opacity: 0, ease: 'power3' })
  gsap.to('.showreelRevealContainer', 0, { clipPath: 'inset(50% 0% 50% 0%)', delay: 1 })

  scene.add(ringGroup, logo)

  cancelOpacityScrollAnimation = true

  menu.style.zIndex = '-1'

  homeContainer.style.display = "flex"
  aboutContainer.style.display = "none"
  workContainer.style.display = "none"

  if (musicPlayed == true) {
    bgMusic.fade(0, 0.5, 1000)
  }

  switchSection = 'home'

  lineContainer__3.classList.remove('switch')
  lineContainer__4.classList.remove('switch')
  crossLine__1.classList.remove('switch')
  crossLine__2.classList.remove('switch')
  hoverCrossLine__1.classList.remove('switch')
  hoverCrossLine__2.classList.remove('switch')
  hamburgerWrapper.style.pointerEvents = "none"

  setTimeout(function () {
    video.pause()
    video.currentTime = 0
    rotateMobileIndication.classList.remove('switch')
  }, 750)

  setTimeout(function () {
    gsap.to(ringMaterial, 1.5, { opacity: .15, ease: 'power3' })
    gsap.to(logoMaterial, 1.5, { opacity: 1, ease: 'power3' })
    gsap.to('.container--home', 1.5, { opacity: 1, transform: 'scale(1)', ease: 'power3' })
    gsap.to('.musicWrapper', 1.5, { opacity: 1, ease: 'power3' })
    gsap.to('#app', 1.5, { opacity: 1, ease: 'power3' })
    gsap.to('.homeLink', 1, { opacity: .6, ease: 'power3', delay: .55 })
    gsap.to('.container--about .title', 0, { opacity: 0, transform: 'scale(1.17)' })
    gsap.to('.container--about .aboutWrapper', 0, { opacity: 0, transform: 'scale(1.17)' })
    gsap.to('.container--about .scrollIndication', 0, { opacity: 0 })
    gsap.to(camera.position, 1.5, { z: 1.5, ease: 'power3' })

    lineContainer__1.classList.remove('switch')
    hoverLine__1.classList.remove('switch')
    hoverLine__2.classList.remove('switch')

    locoScroll.update()
    ScrollTrigger.refresh()

    setTimeout(function () {
      homeBtn.forEach(e => {
        e.style.pointerEvents = 'all'
      })
      hamburgerWrapper.style.pointerEvents = "all"
      audioSwitcher.button.style.pointerEvents = 'all'
      app.style.pointerEvents = 'all'
      switchMenu = false
    }, 750)
  }, 1000)
}

function down() {
  if (switchMenu == false && mousePressedActive == true) {
    mousePressed = true
    switchCursorIncrementation = false

    cursorContinuousIncrement()

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
    }
    else {
      locoScroll.stop()
    }
  }
}

function up() {
  if (switchMenu == false) {
    mousePressed = false
    locoScroll.start()
    switchCursorIncrementation = true

    cursorContinuousDecrement()
  }
}

app.addEventListener('mousedown', () => {
  down()
})

document.addEventListener('mouseup', () => {
  up()
})

app.addEventListener('touchstart', () => {
  down()
})

document.addEventListener('touchend', () => {
  up()
})

function cursorContinuousIncrement() {
  if (switchCursorIncrementation == false && switchToShowreel == false) {
    if (switchSection != 'work' && switchSection != 'workReveal') {
      if (cursorValue < 100) {
        cursorLoader.innerHTML = ++cursorValue
      }

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
        if (cursorValue >= 20) {
          bgMusic.fade(0, 0, 1000)

          if (musicPlayed == true) {
            showreelSound.fade(0.1, 0, 2000)
            showreelSound.play()
          }

          customCursor.style.padding = 20 + cursorValue / 2 + 'px'
          cursorLoader.style.transform = 'translate(-50%, -50%) scale(' + (.75 + cursorValue / 75) + ')'
          gsap.to('#app', .5, { opacity: 0, ease: 'power3' })
          gsap.to('.musicWrapper', .5, { opacity: 0, ease: 'power3' })
          gsap.to('.hamburgerWrapper', .5, { opacity: 0, ease: 'power3' })
          gsap.to(customCursorDot, .5, { opacity: 0, ease: 'power3' })
          gsap.to(customCursor, .5, { opacity: 1, borderRadius: '100%', ease: 'power3' })
          gsap.to(customCursorIndication, .5, { opacity: 0, ease: 'power3' })
          gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 1)', ease: 'power3' })
          gsap.to('.cursorLoader', .5, { opacity: 1, ease: 'power3' })
          gsap.to(customCursorFollower, .5, { opacity: 0, padding: '55px', borderRadius: '100%', ease: 'power3' })

          hamburgerWrapper.style.pointerEvents = 'none'
          audioSwitcher.button.style.pointerEvents = 'none'

          if (switchSection == 'home') {
            gsap.to('.homeLink', .5, { opacity: 0, ease: 'power3' })
            homeBtn.forEach(e => {
              e.style.pointerEvents = 'none'
            })
          }

          if (switchSection == 'about') {
            allImg.forEach(e => {
              e.style.pointerEvents = 'none'
            })
            gsap.to(logoMaterial, .5, { opacity: 1, ease: 'power3' })
          }
        }
      }
      else {
        if (cursorValue >= 5) {
          customCursor.style.padding = 20 + cursorValue / 2 + 'px'
          cursorLoader.style.transform = 'translate(-50%, -50%) scale(' + (.75 + cursorValue / 75) + ')'
          bgMusic.fade(0, 0, 1000)

          if (musicPlayed == true) {
            showreelSound.fade(0.1, 0, 2000)
            showreelSound.play()
          }
          gsap.to('#app', .5, { opacity: 0, ease: 'power3' })
          gsap.to('.musicWrapper', .5, { opacity: 0, ease: 'power3' })
          gsap.to('.hamburgerWrapper', .5, { opacity: 0, ease: 'power3' })
          gsap.to(customCursorDot, .5, { opacity: 0, ease: 'power3' })
          gsap.to(customCursor, .5, { opacity: 1, borderRadius: '100%', ease: 'power3' })
          gsap.to(customCursorIndication, .5, { opacity: 0, ease: 'power3' })
          gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 1)', ease: 'power3' })
          gsap.to('.cursorLoader', .5, { opacity: 1, ease: 'power3' })
          gsap.to(customCursorFollower, .5, { opacity: 0, padding: '55px', borderRadius: '100%', ease: 'power3' })

          hamburgerWrapper.style.pointerEvents = 'none'
          audioSwitcher.button.style.pointerEvents = 'none'

          if (switchSection == 'home') {
            gsap.to('.homeLink', .5, { opacity: 0, ease: 'power3' })
            homeBtn.forEach(e => {
              e.style.pointerEvents = 'none'
            })
          }

          if (switchSection == 'about') {
            allImg.forEach(e => {
              e.style.pointerEvents = 'none'
            })
            gsap.to(logoMaterial, .5, { opacity: 1, ease: 'power3' })
          }
        }
      }

      if (cursorValue == 100) {
        cursorValue = 100
        gsap.to(customCursorDot, .5, { opacity: 1, ease: 'power3.in' })
        gsap.to(customCursor, .5, { opacity: 1, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
        gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
        gsap.to('.cursorLoader', .5, { opacity: 0, ease: 'power3.in' })
        gsap.to(customCursorFollower, .5, { opacity: .5, padding: '0px', borderRadius: '0%', ease: 'power3.in' })

        showreelSound.stop()

        switchToShowreel = true

        openShowreel()

        setTimeout(function () {
          cursorValue = 0
          switchToShowreel = false
        }, 100)
      }
      setTimeout(cursorContinuousIncrement, 50)
    }
  }
}

function cursorContinuousDecrement() {
  if (switchCursorIncrementation == true && cursorValue > 0) {

    cursorLoader.innerHTML = --cursorValue

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
      if (cursorValue >= 20 && cursorValue < 100) {
        customCursor.style.padding = 20 + cursorValue / 2 + 'px'
        cursorLoader.style.transform = 'translate(-50%, -50%) scale(' + (.75 + cursorValue / 75) + ')'
      }

      if (cursorValue >= 20 && cursorValue < 100 && musicPlayed == true && switchSection != 'work' && switchSection != 'workReveal') {
        bgMusic.fade(0, 0.5, 1000)
        showreelSound.stop()
      }

      if (cursorValue > 0 && cursorValue <= 20 && (switchSection == 'home' || switchSection == 'about')) {
        gsap.to('#app', .5, { opacity: 1, ease: 'power3.in' })
        gsap.to('.musicWrapper', .5, { opacity: 1, ease: 'power3.in' })
        gsap.to('.hamburgerWrapper', .5, { opacity: 1, ease: 'power3.in' })
        gsap.to(customCursorDot, .5, { opacity: 1, ease: 'power3.in' })
        gsap.to(customCursor, .5, { opacity: 1, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
        gsap.to(customCursorIndication, .5, { opacity: 1, ease: 'power3.in' })
        gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
        gsap.to('.cursorLoader', .5, { opacity: 0, ease: 'power3.in' })
        gsap.to(customCursorFollower, .5, { opacity: .5, padding: '0px', borderRadius: '0%', ease: 'power3.in' })

        hamburgerWrapper.style.pointerEvents = 'all'
        audioSwitcher.button.style.pointerEvents = 'all'

        if (switchSection == 'home') {
          gsap.to('.homeLink', .5, { opacity: 0.6, ease: 'power3.in' })
          homeBtn.forEach(e => {
            e.style.pointerEvents = 'all'
          })
        }

        if (switchSection == 'about') {
          allImg.forEach(e => {
            e.style.pointerEvents = 'all'
          })
          gsap.to(logoMaterial, .5, { opacity: 0, ease: 'power3.in' })
        }
      }
    }
    else {
      if (cursorValue >= 5 && cursorValue < 100) {
        customCursor.style.padding = 20 + cursorValue / 2 + 'px'
        cursorLoader.style.transform = 'translate(-50%, -50%) scale(' + (.75 + cursorValue / 75) + ')'
      }

      if (cursorValue > 1 && cursorValue < 100 && musicPlayed == true && switchSection != 'work' && switchSection != 'workReveal') {
        bgMusic.fade(0, 0.5, 1000)
        showreelSound.stop()
      }

      if (cursorValue > 0 && cursorValue <= 5 && (switchSection == 'home' || switchSection == 'about')) {

        gsap.to('#app', .5, { opacity: 1, ease: 'power3.in' })
        gsap.to('.musicWrapper', .5, { opacity: 1, ease: 'power3.in' })
        gsap.to('.hamburgerWrapper', .5, { opacity: 1, ease: 'power3.in' })
        gsap.to(customCursorDot, .5, { opacity: 1, ease: 'power3.in' })
        gsap.to(customCursor, .5, { opacity: 1, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
        gsap.to(customCursorIndication, .5, { opacity: 1, ease: 'power3.in' })
        gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
        gsap.to('.cursorLoader', .5, { opacity: 0, ease: 'power3.in' })
        gsap.to(customCursorFollower, .5, { opacity: .5, padding: '0px', borderRadius: '0%', ease: 'power3.in' })

        hamburgerWrapper.style.pointerEvents = 'all'
        audioSwitcher.button.style.pointerEvents = 'all'

        if (switchSection == 'home') {
          gsap.to('.homeLink', .5, { opacity: 0.6, ease: 'power3.in' })
          homeBtn.forEach(e => {
            e.style.pointerEvents = 'all'
          })
        }

        if (switchSection == 'about') {
          allImg.forEach(e => {
            e.style.pointerEvents = 'all'
          })
          gsap.to(logoMaterial, .5, { opacity: 0, ease: 'power3.in' })
        }
      }
    }
    setTimeout(cursorContinuousDecrement, 15)
  }
}

//////---------------------------------------SMOOTH SCROLL---------------------------------------//////
const locoScroll = new LocomotiveScroll({
  el: body,
  smooth: true,
  lerp: 0.065,
  multiplier: .475,
  getDirection: true,
  reloadOnContextChange: true,
  smartphone: {
    smooth: true
  },
  tablet: {
    smooth: true
  }
})

locoScroll.stop()


//////---------------------------------------SCROLL TRIGGER---------------------------------------//////
locoScroll.on("scroll", ScrollTrigger.update)

ScrollTrigger.scrollerProxy(body, {
  scrollTop(value) {
    return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y
  },
  getBoundingClientRect() {
    return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight }
  }
})

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
}
else {
  let proxy = { skew: 0 },
    skewSetter = gsap.quickSetter(".skewElem", "skewY", "deg"),
    clamp = gsap.utils.clamp(-20, 20)

  ScrollTrigger.create({
    onUpdate: (self) => {
      let skew = clamp(self.getVelocity() / -315)

      if (Math.abs(skew) > Math.abs(proxy.skew)) {
        proxy.skew = skew
        gsap.to(proxy, { skew: 0, duration: 0.25, ease: "power3", overwrite: true, onUpdate: () => skewSetter(proxy.skew) })
      }
    }
  })
}

//three.js scrollTrigger
let triggerElement = { a: 0 }

gsap.to(triggerElement, {
  a: 1,
  scrollTrigger: {
    scroller: body,
    ease: "power3",
    onUpdate: (self) => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
      } else {
        if (switchSection != 'showreel') {
          ringMesh.rotation.z = -7 * self.progress
          ringMesh2.rotation.z = -7 * self.progress + .25 * Math.PI
          ringMesh3.rotation.z = -7 * self.progress
          ringMesh4.rotation.z = -7 * self.progress + .25 * Math.PI
          ringMesh5.rotation.z = -7 * self.progress
        }
      }
    }
  }
})

//////---------------------------------------SCROLL EVENTS---------------------------------------//////
//hey section
const heySectionTrigger = function (entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting == true && cancelOpacityScrollAnimation == false) {
      gsap.to('.heySection span', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, transform: 'translateY(0)', stagger: 0.2, ease: 'power3' })
    }
  })
}

const heySectionObserver = new IntersectionObserver(heySectionTrigger, { threshold: .5 })

heySectionObserver.observe(heySection)

//about title
const aboutTitleTrigger = function (entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting == true && cancelOpacityScrollAnimation == false) {
      gsap.to('.container--about .title--about span', 1.25, { opacity: 1, transform: 'translateY(0) rotateX(0) rotateY(0) rotateZ(0)', clipPath: 'inset(0% 0% 0% 0%)', stagger: 0.1, ease: 'power3' })
    }
  })
}

const aboutTitleObserver = new IntersectionObserver(aboutTitleTrigger, { threshold: .5 })

const aboutSectionTitle = "ABOUT"
const aboutSectionTitleSplit = aboutSectionTitle.split('')

aboutSectionTitleSplit.forEach(letter => {
  const char = document.createElement('span')
  char.innerHTML = letter
  aboutTitle.append(char)
})

aboutTitleObserver.observe(aboutTitle)

// about presentation
const aboutPresentationTrigger = function (entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting == true && cancelOpacityScrollAnimation == false) {
      gsap.to('.aboutWrapper--2 p', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, transform: 'translateY(0)', stagger: 0.25, ease: 'power3' })
    }
  })
}

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
  thresholdPresentation = .85
}
else {
  thresholdPresentation = .35
}

const aboutPresentationObserver = new IntersectionObserver(aboutPresentationTrigger, { threshold: thresholdPresentation })

aboutPresentationObserver.observe(aboutPresentation)

//course title
const courseTitleTrigger = function (entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting == true && cancelOpacityScrollAnimation == false) {
      gsap.to('.container--about .title--course span', 1.25, { opacity: 1, transform: 'translateY(0) rotateX(0) rotateY(0) rotateZ(0)', clipPath: 'inset(0% 0% 0% 0%)', stagger: 0.1, ease: 'power3' })
    }
  })
}

const courseTitleObserver = new IntersectionObserver(courseTitleTrigger, { threshold: .5 })

const courseSectionTitle = "STUDIES"
const courseSectionTitleSplit = courseSectionTitle.split('')

courseSectionTitleSplit.forEach(letter => {
  const char = document.createElement('span')
  char.innerHTML = letter
  courseTitle.append(char)
})

courseTitleObserver.observe(courseTitle)

// p1
const courseP1Trigger = function (entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting == true && cancelOpacityScrollAnimation == false) {
      gsap.to('.courseSection--1 p', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, transform: 'translateY(0)', stagger: 0.25, ease: 'power3' })
    }
  })
}

const courseP1Observer = new IntersectionObserver(courseP1Trigger, { threshold: .35 })

courseP1Observer.observe(courseP1)

// p2
const courseP2Trigger = function (entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting == true && cancelOpacityScrollAnimation == false) {
      gsap.to('.courseSection--2 p', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, transform: 'translateY(0)', stagger: 0.25, ease: 'power3' })
    }
  })
}

const courseP2Observer = new IntersectionObserver(courseP2Trigger, { threshold: .35 })

courseP2Observer.observe(courseP2)

// p3
const courseP3Trigger = function (entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting == true && cancelOpacityScrollAnimation == false) {
      gsap.to('.courseSection--3 p', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, transform: 'translateY(0)', stagger: 0.25, ease: 'power3' })
    }
  })
}

const courseP3Observer = new IntersectionObserver(courseP3Trigger, { threshold: .35 })

courseP3Observer.observe(courseP3)

//skills title
const skillsTitleTrigger = function (entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting == true && cancelOpacityScrollAnimation == false) {
      gsap.to('.container--about .title--skills span', 1.25, { opacity: 1, transform: 'translateY(0) rotateX(0) rotateY(0) rotateZ(0)', stagger: 0.1, ease: 'power3', delay: 0 })
      gsap.to('.container--about .title--skills span', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', stagger: 0.1, ease: 'power3' })
    }
  })
}

const skillsTitleObserver = new IntersectionObserver(skillsTitleTrigger, { threshold: .5 })

const skillsSectionTitle = "SKILLS"
const skillsSectionTitleSplit = skillsSectionTitle.split('')

skillsSectionTitleSplit.forEach(letter => {
  const char = document.createElement('span')
  char.innerHTML = letter
  skillsTitle.append(char)
})

skillsTitleObserver.observe(skillsTitle)

// webdev
const skillsWebDevTrigger = function (entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting == true && cancelOpacityScrollAnimation == false) {
      gsap.to('.skillsSection--1 span', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, transform: 'translateY(0)', ease: 'power3' })
      setTimeout(function () {
        gsap.to('.skillsSection--1 li', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, transform: 'translateY(0)', stagger: 0.1, ease: 'power3' })
      }, 300)
    }
  })
}

const skillsWebDevObserver = new IntersectionObserver(skillsWebDevTrigger, { threshold: .35 })

skillsWebDevObserver.observe(skillsWebDev)

// design
const skillsDesignTrigger = function (entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting == true && cancelOpacityScrollAnimation == false) {
      gsap.to('.skillsSection--2 span', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, transform: 'translateY(0)', ease: 'power3' })
      setTimeout(function () {
        gsap.to('.skillsSection--2 li', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, transform: 'translateY(0)', stagger: 0.1, ease: 'power3' })
      }, 300)
    }
  })
}

const skillsDesignObserver = new IntersectionObserver(skillsDesignTrigger, { threshold: .35 })

skillsDesignObserver.observe(skillsDesign)

// motion
const skillsMotionTrigger = function (entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting == true && cancelOpacityScrollAnimation == false) {
      gsap.to('.skillsSection--3 span', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, transform: 'translateY(0)', ease: 'power3' })
      setTimeout(function () {
        gsap.to('.skillsSection--3 li', 1.25, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, transform: 'translateY(0)', stagger: 0.1, ease: 'power3' })
      }, 300)
    }
  })
}

const skillsMotionObserver = new IntersectionObserver(skillsMotionTrigger, { threshold: .35 })

skillsMotionObserver.observe(skillsMotion)

function scrollTriggerOpacity() {
  if ((switchSection == "about" || switchSection == "work") && cancelOpacityScrollAnimation == false && mousePressed == false) {

    let sphereWireframeAnimation = gsap.timeline({ default: { ease: "power3" } })
      .to(sphereWireframeMaterial, 1, { opacity: 0.1 })
      .to(sphereWireframeMaterial, 1, { opacity: 0.015, delay: -0.8 })
  }

  if (switchSection == "about" && cancelOpacityScrollAnimation == false && mousePressed == false) {
    gsap.to(".container--about .scrollIndication", .5, { opacity: 0, delay: .25 })

    let ringAnimation = gsap.timeline({ default: { ease: "power3" } })
      .to(ringMaterial, 1, { opacity: 1 })
      .to(ringMaterial, 1, { opacity: 0.15, delay: -0.8 })
  }
}

function scrollUp() {
  if (switchSection == "about" && cancelOpacityScrollAnimation == false && mousePressed == false) {
    boolRingRotation = true
  }
  scrollTriggerOpacity()
}

function scrollDown() {
  if (switchSection == "about" && cancelOpacityScrollAnimation == false && mousePressed == false) {
    boolRingRotation = false
  }
  scrollTriggerOpacity()
}

//function scrollKey
document.onkeydown = checkKey

function checkKey(event) {
  event = event || window.event

  if (event.keyCode == '38') {
    scrollUp()
  }
  else if (event.keyCode == '40') {
    scrollDown()
  }
}

//function scrollWheel
document.addEventListener('wheel', onDocumentScroll)

function onDocumentScroll(event) {
  //Scroll Up
  if (checkScrollDirectionIsUp(event)) {
    scrollUp()
  }
  //Scroll Down
  else {
    scrollDown()
  }
}

function checkScrollDirectionIsUp(event) {
  if (event.wheelDeltaY) {
    return event.wheelDeltaY > 0
  }
  return event.deltaY < 0
}

//update on refresh
ScrollTrigger.addEventListener("refresh", () => locoScroll.update())
ScrollTrigger.refresh()

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
} else {
  //////---------------------------------------CUSTOM CURSOR---------------------------------------//////
  gsap.set(customCursorDot, { xPercent: -50, yPercent: -50 })
  gsap.set(customCursor, { xPercent: -50, yPercent: -50 })
  gsap.set(customCursorIndication, { xPercent: -50, yPercent: -50 })
  gsap.set(customCursorFollower, { xPercent: -50, yPercent: -50 })

  gsap.ticker.add(() => {
    customCursorDotPos.x += (customCursorDotMouse.x - customCursorDotPos.x) * customCursorSpeed
    customCursorDotPos.y += (customCursorDotMouse.y - customCursorDotPos.y) * customCursorSpeed
    customCursorPos.x += (customCursorMouse.x - customCursorPos.x) * customCursorSpeed
    customCursorPos.y += (customCursorMouse.y - customCursorPos.y) * customCursorSpeed
    customCursorIndicationPos.x += (customCursorIndicationMouse.x - customCursorIndicationPos.x) * customCursorSpeed
    customCursorIndicationPos.y += (customCursorIndicationMouse.y - customCursorIndicationPos.y) * customCursorSpeed
    customCursorFollowerPos.x += (customCursorFollowerMouse.x - customCursorFollowerPos.x) * customCursorSpeed
    customCursorFollowerPos.y += (customCursorFollowerMouse.y - customCursorFollowerPos.y) * customCursorSpeed
    customCursorDotXSet(customCursorDotPos.x)
    customCursorDotYSet(customCursorDotPos.y)
    customCursorXSet(customCursorPos.x)
    customCursorYSet(customCursorPos.y)
    customCursorIndicationXSet(customCursorIndicationPos.x)
    customCursorIndicationYSet(customCursorIndicationPos.y)
    customCursorFollowerXSet(customCursorFollowerPos.x)
    customCursorFollowerYSet(customCursorFollowerPos.y)
  })

  //////---------------------------------------MOUSEMOVE EVENTS---------------------------------------//////
  document.addEventListener('mousemove', onMouseMove)

  function onMouseMove(event) {
    resetActive()

    mouseX = (event.clientX - windowHalfX)
    mouseY = (event.clientY - windowHalfY)

    gsap.to(lightMouse, 1.25, {
      x: (event.clientX / window.innerWidth) * 2 - 1,
      y: -(event.clientY / window.innerHeight) * 1 + 0.5,
      ease: "power3"
    })

    gsap.to(customCursorDotMouse, {
      duration: .2,
      x: event.x,
      y: event.y,
      ease: 'power4.out',
      overwrite: true,
    })

    gsap.to(customCursorMouse, {
      duration: .4,
      x: event.x,
      y: event.y,
      ease: 'power4.out',
      overwrite: true,
    })

    gsap.to(customCursorIndicationMouse, {
      duration: .4,
      x: event.x,
      y: event.y,
      ease: 'power4.out',
      overwrite: true,
    })

    gsap.to(customCursorFollowerMouse, {
      duration: .5,
      x: event.x,
      y: event.y,
      ease: 'power4.out',
      overwrite: true,
    })
  }
}

//////---------------------------------------ANIMATE---------------------------------------//////
let tick = function () {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
  } else {
    targetX = mouseX * -.00025
    targetXInvert = mouseX * .00025
    targetY = mouseY * .00025

    if (logo) {
      logo.rotation.z += 0.025 * (targetX - logo.rotation.z * smooth * .5)
      logo.rotation.x += 0.025 * (targetY - (logo.rotation.x - Math.PI * .5) * smooth * .5)
    }

    ringMesh.rotation.y += 0.02 * (targetXInvert - ringMesh.rotation.y * smooth * .5)
    ringMesh.rotation.x += 0.02 * (targetY - ringMesh.rotation.x * smooth * .5)
    ringMesh2.rotation.y += 0.02 * (targetXInvert - ringMesh2.rotation.y * smooth * .5)
    ringMesh2.rotation.x += 0.02 * (targetY - ringMesh2.rotation.x * smooth * .7)
    ringMesh3.rotation.y += 0.02 * (targetXInvert - ringMesh3.rotation.y * smooth * .5)
    ringMesh3.rotation.x += 0.02 * (targetY - ringMesh3.rotation.x * smooth * .5)
    ringMesh4.rotation.y += 0.02 * (targetXInvert - ringMesh4.rotation.y * smooth * .5)
    ringMesh4.rotation.x += 0.02 * (targetY - ringMesh4.rotation.x * smooth * .5)
    ringMesh5.rotation.y += 0.02 * (targetXInvert - ringMesh5.rotation.y * smooth * .5)
    ringMesh5.rotation.x += 0.02 * (targetY - ringMesh5.rotation.x * smooth * .5)

    sphereWireframeMesh.rotation.y += 0.04 * (targetXInvert - sphereWireframeMesh.rotation.y * smooth * .6)
    sphereWireframeMesh.rotation.x += 0.04 * (targetY - sphereWireframeMesh.rotation.x * smooth * .6)

    cylinderWorkMesh.rotation.y += 0.04 * (targetXInvert - cylinderWorkMesh.rotation.y * smooth * .6)
    cylinderWorkMesh.rotation.x += 0.04 * (targetY - cylinderWorkMesh.rotation.x * smooth * .6)
  }

  if (mousePressed == true && pressEvent == true && switchMenu == false && switchSection != "workReveal") {
    if (noiseCustomPass.uniforms.intensity.value <= 0.15) {
      noiseCustomPass.uniforms.intensity.value += noiseCustomPass.uniforms.intensity.value * 0.02
    }

    if (noiseCustomPass.uniforms.intensity.value >= 0.15) {
      noiseCustomPass.uniforms.intensity.value = 0.15
    }

    if (switchSection == 'home' || switchSection == 'about') {
      if (ringMaterial.opacity <= 0.6) {
        ringMaterial.opacity += ringMaterial.opacity * 0.02
      }

      if (ringGroupRotation <= .04) {
        ringGroupRotation += ringGroupRotation * 0.04
      }

      if (ringGroupRotation >= .04) {
        ringGroupRotation = .04
      }

      if (ambientLight.intensity >= 1.2) {
        ambientLight.intensity += ambientLight.intensity * 0.05
        pointLight.intensity += pointLight.intensity * 0.05
      }

      if (ambientLight.intensity >= 25) {
        ambientLight.intensity = 25
        pointLight.intensity = 25
      }

      if (camera.position.z >= 1) {
        camera.position.z -= camera.position.z * 0.002

        shakeX += 5

        camera.position.x += Math.cos(shakeX) / 2000
      }

      if (camera.position.z <= 1) {
        camera.position.z = 1
      }

      if (ringGroup.position.z >= 0) {
        ringGroup.position.z += ringGroup.position.z * 0.02
      }

      if (ringGroup.position.z >= 2) {
        ringGroup.position.z = 2
      }
    }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
    }
    else {
      if (switchSection == 'work' && noiseCustomPass.uniforms.intensity.value > 0.1) {
        gsap.to('#app', .5, { opacity: 0, ease: 'power3' })
        gsap.to('.musicWrapper', .5, { opacity: 0, ease: 'power3' })
        gsap.to('.hamburgerWrapper', .5, { opacity: 0, ease: 'power3' })
        gsap.to(customCursorDot, .5, { opacity: 0, ease: 'power3' })
        gsap.to(customCursor, .5, { opacity: 0, padding: '45px', borderRadius: '100%', ease: 'power3' })
        gsap.to(customCursorIndication, .5, { opacity: 0, ease: 'power3' })
        gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 1)', ease: 'power3' })
        gsap.to(customCursorFollower, .5, { opacity: 0, padding: '55px', borderRadius: '100%', ease: 'power3' })
        hamburgerWrapper.style.pointerEvents = 'none'
        audioSwitcher.button.style.pointerEvents = 'none'
        project.forEach(e => {
          e.style.pointerEvents = 'none'
        })
      }
    }
  }

  else if (mousePressed == false && pressEvent == true && switchMenu == false && switchSection != "workReveal") {
    if (noiseCustomPass.uniforms.intensity.value >= 0.08) {
      noiseCustomPass.uniforms.intensity.value -= noiseCustomPass.uniforms.intensity.value * 0.02
    }

    if (ringMaterial.opacity >= 0.15) {
      ringMaterial.opacity -= ringMaterial.opacity * 0.02
    }

    if (ringGroupRotation > .003) {
      ringGroupRotation -= ringGroupRotation * 0.015
    }

    if (switchSection == 'home' || switchSection == 'about' || switchSection == 'showreel') {
      if (ambientLight.intensity >= 1.2) {
        ambientLight.intensity -= ambientLight.intensity * 0.05
        pointLight.intensity -= pointLight.intensity * 0.05
      }

      if (ambientLight.intensity <= 1.2) {
        ambientLight.intensity = 1.2
        pointLight.intensity = 1.2
      }

      if (camera.position.z < 1.5) {
        camera.position.z += camera.position.z * 0.003
      }

      if (camera.position.z >= 1.5) {
        camera.position.z = 1.5
      }

      if (camera.position.x = !0) {
        camera.position.x = 0
        shakeX = 0
      }

      if (ringGroup.position.z > 0.05) {
        ringGroup.position.z -= ringGroup.position.z * 0.02
      }

      if (ringGroup.position.z <= 0.05) {
        ringGroup.position.z = 0.05
      }
    }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 1024px)").matches) {
    }
    else {
      if (switchSection == 'work' && noiseCustomPass.uniforms.intensity.value > 0.1) {
        gsap.to('#app', .5, { opacity: 1, ease: 'power3.in' })
        gsap.to('.musicWrapper', .5, { opacity: 1, ease: 'power3.in' })
        gsap.to('.hamburgerWrapper', .5, { opacity: 1, ease: 'power3.in' })

        gsap.to(customCursorDot, .5, { opacity: 1, ease: 'power3.in' })
        gsap.to(customCursor, .5, { opacity: 1, padding: '0px', borderRadius: '0%', ease: 'power3.in' })
        gsap.to(customCursorIndication, .5, { opacity: 1, ease: 'power3.in' })
        gsap.to('.octagon', .5, { background: 'rgba(177, 177, 177, 0)', ease: 'power3.in' })
        gsap.to(customCursorFollower, .5, { opacity: .5, padding: '0px', borderRadius: '0%', ease: 'power3.in' })

        project.forEach(e => {
          e.style.pointerEvents = 'all'
        })
        hamburgerWrapper.style.pointerEvents = 'all'
        audioSwitcher.button.style.pointerEvents = 'all'
      }
    }
  }

  if (!boolRingRotation) {
    ringGroup.rotation.z -= ringGroupRotation
  }
  else {
    ringGroup.rotation.z += ringGroupRotation
  }

  noiseRenderer.render(noiseScene, noiseCamera)
  renderer.render(scene, camera)

  if (noiseCustomPass.uniforms["amount"].value <= 0) {
    switchNoise = false
  }

  if (noiseCustomPass.uniforms["amount"].value >= 0.5) {
    switchNoise = true
  }

  if (switchNoise == false) {
    noiseCustomPass.uniforms["amount"].value += 0.001
  }

  if (switchNoise == true) {
    noiseCustomPass.uniforms["amount"].value -= 0.001
  }

  let time = performance.now() / 1000
  if (audioSwitcher) {
    audioSwitcher.animate(time)
  }

  window.requestAnimationFrame(tick)

  noiseComposer.render()
}

tick()